import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.steps),function(step,stepIndex){return _c(VCol,{key:`${stepIndex}-step`,staticClass:"pa-0 d-flex flex-column justify-center align-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"height":"60px","position":"relative","width":"100%"}},[_c(VSheet,{staticClass:"d-flex justify-center align-center rounded-circle",staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","z-index":"2"},style:(_vm.stepValidation(step, stepIndex) ? 'border: 3px solid #60D3C7;' : 'border: 3px solid #E0E0E0 !important;'),attrs:{"height":"60","width":"60","color":_vm.stepValidation(step, stepIndex) ? '#D8EAE8' : 'white'}},[_c(VImg,{attrs:{"height":"34","max-height":"34","max-width":"34","contain":"","src":_vm.setStepperIcon(_vm.stepValidation(step, stepIndex) ? step.code : `${step.code}-alt`)}})],1),(stepIndex < _vm.steps.length -1)?_c(VDivider,{style:(`
            width: 100%;
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(0%) translateY(-50%);
            border-width: medium;
            border-color: ${_vm.stepBarValidation(step) ? '#60D3C7' : '#E0E0E0'};`)}):_vm._e()],1),_c('div',{staticStyle:{"height":"160px"}},[_c('p',{staticClass:"font-weight-medium text-center mb-4",style:(`color: ${_vm.stepValidation(step, stepIndex) ? 'black' : '#808080'}; height: 50px;`)},[_vm._v(" "+_vm._s(step.name)+" ")]),(
            _vm.calculateCompletedTasks(step.tasks) !== _vm.calculateTotalTasks(step.tasks)
            && _vm.stepValidation(step, stepIndex))?_c('div',{staticClass:"px-2 d-flex flex-column"},[_c('ProgressLoader',{attrs:{"loaded":_vm.calculeTasksPercentage(step.tasks),"inner":true}}),_c('div',{staticStyle:{"min-height":"32px"}},[(_vm.stepValidation(step, stepIndex))?_c(VBtn,{staticClass:"rounded-lg mt-2 white--text",staticStyle:{"max-height":"44px"},attrs:{"large":"","block":"","color":"#5555A5"},on:{"click":_vm.goToSendDocuments}},[_c('span',{staticClass:"text-capitalize text-subtitle-1"},[_vm._v(" Enviar Documentos ")])]):_vm._e()],1)],1):_c('div',{staticClass:"d-flex justify-center align-end pt-2"},[_c('span',{staticStyle:{"color":"#808080"}},[_vm._v(" "+_vm._s((_vm.calculateCompletedTasks(step.tasks)))+"/"+_vm._s(_vm.calculateTotalTasks(step.tasks))+" ")])])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
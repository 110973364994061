<template>
  <v-btn
    v-on="on"
    v-bind="attrs"
    class="elevation-1"
    :height="height"
    :width="width"
    :color="color"
    :disabled="disabled"
    fab
    @click="$emit('click')">
    <v-icon
      :color="iconColor"
      :size="iconSize">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ActionBtn",

  props: {
    icon: {
      type: String,
      default: "far fa-edit",
    },
    color: {
      type: String,
      default: "#5555A5",
    },
    iconColor: {
      type: String,
      default: "white",
    },
    iconSize: {
      type: String,
      default: "22",
    },
    height: {
      type: String,
      default: "40",
    },
    width: {
      type: String,
      default: "40",
    },
    on: {
      requred: false,
    },
    attrs: {
      required: false,
    },
    disabled: {
      default: false,
    },
  }
}
</script>
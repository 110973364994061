<template>
  <div>
    <SendDocumentsTable
      :model="model"
      :items="contractChecklist"
      :config="config">
      <template v-slot:[`item.carrierName`]="{ item }">
        <span v-html="item.carrierName" />
      </template>

      <template v-slot:[`item.documentDTOS`]="{ item }">
        {{ item.documentDTOS ?  item.documentDTOS.length : 0 }} anexos
      </template>

      <template v-slot:[`item.fill`]="{ item }">
        <div
          style="width: 100%;">
          <ProgressLoader
            :loaded="setLoader(item.documentDTOS)"
          />
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <StatusBadge
          :label="setTemplateStatus(item).label"
          :color="setTemplateStatus(item).color"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <ActionBtn
          :key="item.id"
          @click="openModal(item)"
        />
      </template>
    </SendDocumentsTable>

    <Modal
      ref="ContractsAndTermsModal"
      :title="'Contratos e Termos'">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: 500px; overflow: auto;"
          class="pb-0">
          <ModalContentTable
            :items="documents"
            :config="modalConfig">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <v-sheet
                  class="rounded-circle d-flex justify-center align-center mr-1"
                  min-height="16"
                  min-width="16"
                  style="border: 2px solid #E0E0E0"
                  :color="setStatusColor({code: item.status})">
                </v-sheet>


                <span v-html="item.name" />
              </div>
            </template>

            <template v-slot:[`item.model`]="{ item }">
              <v-img
                :key="item.id"
                color="#5FD3C7"
                max-height="22"
                height="22"
                max-width="22"
                contain
                :src="getDocumentSvg"
              />
            </template>

            <template v-slot:[`item.attachment`]="{ item }">
              <v-menu
                :id="item.id"
                bottom
                right
                open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#5FD3C7"
                    size="17"
                    v-on="on"
                    v-bind="attrs">
                    fas fa-paperclip
                  </v-icon>
                </template>

                <v-list
                  style="background-color: #DCF0EE;">
                  <div
                    v-if="false">
                    <v-list-item
                      v-for="(document, i) in item.documentDTOS"
                      :key="i"
                      class="py-0">
                      <v-list-item-icon>
                        <v-icon
                          color="#5FD3C7"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        {{ document.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>

                  <div
                    v-else
                    class="px-2">
                    <span
                      style="color: #44B7AB;">
                      Nenhum anexo encontrado
                    </span>
                  </div>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.comments`]="{ item }">
              <v-icon
                :key="item.id"
                color="#8D8D8D"
                size="17"
                @click="openCommentModal">
                far fa-comment
              </v-icon>
            </template>

            <template v-slot:[`item.critics`]="{ item }">
              <v-icon
                :key="item.id"
                color="#5555A5"
                size="17"
                @click="openCriticsModal(item)">
                fas fa-exclamation-triangle
              </v-icon>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <FileDropzone
                v-if="!item.documentTypeField || item.documentTypeField === 'file'"
                :isMultiple="false"
                @files="setFile(item, $event)"
              />

              <TextField
                v-if="['smartphone', 'email', 'text'].includes(item.documentTypeField)"
                :attrs="{
                  title: setTitle(item),
                  rules: setRuleTypes(item),
                  vMask: setDocumentTypeFieldMask(item),
                }"
                @updateValue="setDocumentValue(item, $event)"
              />
            </template>
          </ModalContentTable>

          <v-divider />

          <div
            class="px-3 pt-2">
            <v-container
              fluid>
              <v-row>
                <v-spacer />

                <v-btn
                  large
                  color="primary"
                  class="white--text mr-6 rounded-lg"
                  width="200">
                  Cancelar
                </v-btn>

                <v-btn
                  large
                  color="#5FD3C7"
                  class="black--text rounded-lg"
                  width="200"
                  :disabled="checklistPayload.length === 0"
                  @click="updateChecklist">
                  Salvar
                </v-btn>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </Modal>

    <CommentModal
      ref="CommentModal"
    />

    <CriticsModal
      ref="CriticsModal"
    />
  </div>
</template>

<script>
import ProgressLoader from '../../components/structure/progressLoader.vue';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import StatusBadge from '../../components/structure/statusBadge.vue';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
import TextField from '../../components/structure/textField.vue';
import DocumentService from '@/services/odoo/documentService.js';
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import ModalContentTable from './modalContentTable.vue';
import { mapGetters, mapMutations } from 'vuex';
import CriticsModal from './criticsModal.vue';
import CommentModal from './commentModal.vue';
import ActionBtn from './actionBtn.vue';

export default {
  name: "ContractsAndTermsTable",

  components: {
    SendDocumentsTable,
    ModalContentTable,
    ProgressLoader,
    FileDropzone,
    CriticsModal,
    CommentModal,
    StatusBadge,
    ActionBtn,
    TextField,
    Modal,
  },

  data: () => ({
    model: [],
    documents: [],
    checklistPayload: [],
    documentService: null,
  }),

  mixins: [
    DocumentsMixins,
    AttachmentMixin,
  ],

  methods: {
    ...mapMutations(["loading"]),

    openModal(item) {
      this.documents = item.documentDTOS;

      this.$refs.ContractsAndTermsModal.open();
    },

    setLoader(documents) {
      const documentsCompleted = documents.filter((document) => document.status === "completed");
      const amount = documentsCompleted && documentsCompleted.length > 0 ? documentsCompleted.length : 0;

      return (amount / documents.length) * 100;
    },

    openCommentModal() {
      this.$refs.CommentModal.$refs.modal.open();
    },

    openCriticsModal(item) {
      this.$refs.CriticsModal.open(item.documentDTOS);
    },

    async updateChecklist() {
      for (let checklist of this.checklistPayload) {
        if (checklist.fileContent) {
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      this.loading(true);

      await this.documentService.UpdateChecklist(this.checklistPayload)
        .finally(() => this.loading(false));
    },

    setDocumentTypeFieldMask(item) {
      if (!item.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'smartphone':
          return  "(##) #####-####";
        default:
          return null
      }
    },

    setTitle(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    setRuleTypes(item) {
      if (!item.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'email':
          return  [this.formrules.email(item.documentValue)];

        case 'smartphone':
          return  [this.formrules.cellphone(item.documentValue)];

        default:
          return [true];
      }
    },

    setTextFieldMask(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    setFile(item, file) {
      item.file = file;

      this.updatePayload(item);
    },

    setDocumentValue(item, documentValue) {
      item.documentValue = documentValue;

      this.updatePayload(item);
    },

    updatePayload(documentDTO) {
      const dtoIndex = this.checklistPayload.findIndex((dto) => dto.id === documentDTO.id);

      const isFile = !documentDTO.documentTypeField || documentDTO.documentTypeField === 'file';

      if (dtoIndex === -1) {
        this.checklistPayload.push({
          id: documentDTO.id,
          fileContent: documentDTO.file,
          fileName: isFile ? documentDTO.file.name : null,
          documentValue: !isFile ? documentDTO.documentValue : null,
        })
      } else {
        if (!documentDTO.documentValue && !documentDTO.file) {
          this.checklistPayload.splice(dtoIndex, 1);
          return;
        }

        if (isFile) {
          this.checklistPayload[dtoIndex].fileContent = documentDTO.file;
          this.checklistPayload[dtoIndex].documentValue = null;
        } else {
          this.checklistPayload[dtoIndex].fileContent = null;
          this.checklistPayload[dtoIndex].documentValue = documentDTO.documentValue;
        }
      }
    },
  },

  computed: {
    ...mapGetters(["getChecklist"]),

    getDocumentSvg() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/download-document.svg" ;
    },

    contractChecklist() {
      const contracts = this.getChecklist.find((checklist) => checklist.checklistLevelCode === 'CONTRACT');
      return contracts.levelDTOS && contracts.levelDTOS.length > 0 ? contracts.levelDTOS : [];
    },

    config() {
      return {
        headers: [
          {
            text: 'Benefício',
            value: 'carrierName',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de anexos',
            value: 'documentDTOS',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: '% de Preenchimento',
            value: 'fill',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    modalConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Modelo',
            value: 'model',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 1,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }
          },
        ]
      }
    },
  },

  created() {
    this.documentService = new DocumentService();
  },
}
</script>
<template>
  <div>
    <SendDocumentsTable
      :model="model"
      :items="beneficiariesChecklist"
      :config="config">
      <template v-slot:[`item.benefit`]="{ item }">
        <span v-html="item.carrierName"></span>
      </template>
      <template v-slot:[`item.attachmentNumber`]="{ item }">
        {{ item.familyGroupDTOS ? countBeneficiaryFiles(item) : 0 }} anexos
      </template>
      <template v-slot:[`item.fill`]="{ item }">
        <div style="width: 100%;">
          <ProgressLoader
            :loaded="setLoader(item.familyGroupDTOS)"
          />
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <StatusBadge
          :label="setHeathStatus(item).label"
          :color="setHeathStatus(item).color"
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <ActionBtn
          @click="openModal(item)"
        />
      </template>
    </SendDocumentsTable>

    <Modal
      ref="BeneficiariesModal"
      :title="'Beneficiários'">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: 500px; overflow: auto;"
          class="pb-0">
          <ModalContentTable
            :items="beneficiaries"
            :config="modalConfig">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <v-sheet
                  class="rounded-circle d-flex justify-center align-center mr-1"
                  min-height="16"
                  min-width="16"
                  :color="setBeneficiaryHeathStatus(item).color">
                </v-sheet>

                <span
                  v-html="item.holderName"
                />
              </div>
            </template>

            <template v-slot:[`item.dependentNumber`]="{ item }">
              <span>{{ item.quantityBeneficiaries }} dependentes</span>
            </template>

            <template v-slot:[`item.attachment`]="{ item }">
              <v-menu
                :id="item.id"
                bottom
                right
                open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#5FD3C7"
                    size="17"
                    v-on="on"
                    v-bind="attrs">
                    fas fa-paperclip
                  </v-icon>
                </template>

                <v-list
                  style="background-color: #DCF0EE;">
                  <div
                    v-if="item.document">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon
                          color="#5FD3C7"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        {{ document.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>

                  <div
                    v-else
                    class="px-2">
                    <span
                      style="color: #44B7AB;">
                      Nenhum anexo encontrado
                    </span>
                  </div>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.critics`]="{ item }">
              <v-icon
                :key="item.id"
                color="#5555A5"
                size="17"
                v-if="hasBeneficiaryRiskDocumentsValidation(item) > 0"
                @click="openCriticsModal(item)">
                fas fa-exclamation-triangle
              </v-icon>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <ActionBtn
                @click="editFamilyGroup(item)"
              />
            </template>
          </ModalContentTable>
        </div>
      </template>

      <template slot="action">
        <v-row
          class="d-flex justify-center my-1"
          style="background-color: #F6F2FC">
          <v-col cols="4" />
          <v-col cols="4" class="d-flex justify-center">
            <div
              @click="addFinancialGroup"
              style="cursor: pointer;">
              <ActionBtn
                :icon="'fas fa-plus'"
              />
              <span
                style="color: #5555A5; font-size: 14px;">
                Incluir Grupo Familiar
              </span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-center">
            Total: {{ totalBeneficiaies }} beneficiários
          </v-col>
        </v-row>
      </template>
    </Modal>

    <Modal
      ref="FinancialGroupModal"
      :title="'Inclusão'"
      @close="closeFamilyGroupModal">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: 500px; overflow: auto;"
          class="pb-0 px-4">
          <v-form
            ref="FamilyGroupForm">
            <p
              class="my-4"
              style="font-size: 14px; font-weight: bold;">
              Dados do Titular
            </p>

            <v-row>
              <v-col
                cols="4"
                class="d-flex align-center">
                <v-text-field
                  label="Nome *"
                  outlined
                  v-model="familyGroup.holderName"
                  hide-details
                  @change="updateHolderName"
                  :rules="[formrules.required]"
                />
              </v-col>

              <v-col
                cols="4"
                class="d-flex align-center">
                <v-tooltip
                  bottom>
                  <template
                    v-slot:activator="{ on, attrs }">
                    <ActionBtn
                      :icon="'fas fa-plus'"
                      :attrs="attrs"
                      :on="on"
                      @click="addDependent"
                    />
                  </template>
                  <span>Adicionar dependente</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <div
              v-for="(beneficiary, beneficiaryIndex) in familyGroup.beneficiaryDTOs"
              :key="`beneficiary-${beneficiaryIndex}`"
              :id="`beneficiary-${beneficiaryIndex}`">
              <v-row>
                <v-col
                  cols="12">
                  <v-row
                    class="my-4">
                    <v-col
                      cols="4"
                      class="mb-0">
                      <v-text-field
                        v-if="!beneficiary.holder"
                        label="Nome do Dependente *"
                        outlined
                        v-model="beneficiary.beneficiaryName"
                        hide-details
                        :rules="[formrules.required]"
                      />
                    </v-col>
                    <v-col
                      cols="8"
                      class="d-flex justify-start align-center">
                      <v-tooltip
                        bottom
                        v-if="!beneficiary.holder">
                        <template
                          v-slot:activator="{ on, attrs }">
                          <ActionBtn
                            :icon="'fas fa-times'"
                            :attrs="attrs"
                            :on="on"
                            color="red"
                            class="mr-4"
                            @click="removeDependent"
                          />
                        </template>
                        <span>Remover dependente</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <p
                    class="ml-3 mt-6 mb-0 pb-0"
                    style="font-size: 14px; font-weight: bold;">
                    {{
                      beneficiary.holder
                      ? "Documentos do Titular"
                      : `Documentos do Dependente ${beneficiaryIndex.toString().padStart(2, '0')}`
                    }}
                  </p>
                </v-col>

                <v-col
                  cols="12"
                  class="py-0">
                  <div
                    v-for="(document, documentIndex) in beneficiary.documentDTOS"
                    :key="`documentDTOS-${documentIndex}`">
                    <v-container
                      fluid>
                      <v-row
                        class="d-flex align-center">
                        <v-col
                          cols="6">
                          <p>
                            {{
                              document.name
                            }}
                          </p>
                        </v-col>
                        <v-col
                          cols="6"
                          class="d-flex justify-end">
                          <div
                            style="width: 300px; max-width: 300px;">
                            <FileDropzone
                              v-if="!document.documentTypeField || document.documentTypeField === 'file'"
                              :isMultiple="false"
                              @files="setFile(document, $event)"
                            />

                            <TextField
                              v-if="['smartphone', 'email', 'text'].includes(document.documentTypeField)"
                              :attrs="{
                                title: setTitle(document),
                                rules: setRuleTypes(document),
                              }"
                              @updateValue="setDocumentValue(document, $event)"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider />
                  </div>
                  <v-divider />
                </v-col>
              </v-row>
            </div>
          </v-form>

          <div
            class="pt-2">
            <v-container
              fluid>
              <div
                  class="pt-2 pb-7">
                  <UserMessage
                    :message="'Para uma melhor organização, recomendamos\
                      fazer o upload de cada documento  conforme a ordem do\
                      check-list.<br>Caso prefira, você também pode enviar\
                      todos os arquivos de uma vez, <strong>clicando abaixo</strong>.'"
                  />
                </div>

              <v-row
                class="px-3">
                <v-btn
                  large
                  outlined
                  color="primary"
                  class="white--text rounded-lg elevation-1 text-capitalize"
                  @click="openNewDocumentModal"
                  :disabled="!familyGroup || familyGroup.length === 0">
                  Inserir novo documento
                </v-btn>

                <v-spacer />

                <v-btn
                  large
                  color="primary"
                  class="white--text mr-6 rounded-lg text-capitalize"
                  width="200"
                  @click="closeFamilyGroupModal"
                  :disabled="loadingFamilyGroupModal">
                  Cancelar
                </v-btn>

                <v-btn
                  large
                  color="#5FD3C7"
                  class="black--text rounded-lg text-capitalize"
                  width="200"
                  :disabled="
                    !familyGroup.beneficiaryDTOs
                    || familyGroup.beneficiaryDTOs
                    && familyGroup.beneficiaryDTOs.length === 0"
                  @click="setFamilyGroup(familyGroup)"
                  :loading="loadingFamilyGroupModal">
                  Salvar
                </v-btn>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </Modal>

    <Modal
      ref="LayoutModal"
      :title="'Beneficiários'">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: 500px; overflow: auto;"
          class="pb-0">
          <ModalContentTable
            :items="documents"
            :config="layoutConfig">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <v-sheet
                  class="rounded-circle d-flex justify-center align-center mr-1"
                  min-height="16"
                  min-width="16"
                  style="border: 2px solid #E0E0E0"
                  :color="setStatusColor({code: item.status})">
                </v-sheet>


                <span v-html="item.name" />
              </div>
            </template>

            <template v-slot:[`item.model`]="{ item }">
              <v-img
                :key="item.id"
                color="#5FD3C7"
                max-height="22"
                contain
                :src="getDocumentSvg"
              />
            </template>

            <template v-slot:[`item.attachment`]="{ item }">
              <v-menu
                :id="item.id"
                bottom
                right
                open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#5FD3C7"
                    size="17"
                    v-on="on"
                    v-bind="attrs">
                    fas fa-paperclip
                  </v-icon>
                </template>

                <v-list
                  style="background-color: #DCF0EE;">
                  <div
                    v-if="false">
                    <v-list-item
                      v-for="(document, i) in item.documentDTOS"
                      :key="i"
                      class="py-0">
                      <v-list-item-icon>
                        <v-icon
                          color="#5FD3C7"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        {{ document.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>

                  <div
                    v-else
                    class="px-2">
                    <span
                      style="color: #44B7AB;">
                      Nenhum anexo encontrado
                    </span>
                  </div>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.comments`]="{ item }">
              <v-icon
                :key="item.id"
                color="#8D8D8D"
                size="17"
                @click="openCommentModal(item)">
                far fa-comment
              </v-icon>
            </template>

            <template v-slot:[`item.critics`]="{ item }">
              <v-icon
                :key="item.id"
                color="#5555A5"
                size="17"
                @click="openCriticsModal(item)">
                fas fa-exclamation-triangle
              </v-icon>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <FileDropzone
                v-if="!item.documentTypeField || item.documentTypeField === 'file'"
                @files="setFile(item, $event)"
              />

              <TextField
                v-if="['smartphone', 'email', 'text'].includes(item.documentTypeField)"
                :attrs="{title: setTitle(item)}"
              />
            </template>
          </ModalContentTable>

          <v-divider />

          <div
            class="px-3 pt-2">
            <v-container
              fluid>
              <v-row>
                <v-spacer />

                <v-btn
                  large
                  color="primary"
                  class="white--text mr-6 rounded-lg"
                  width="200"
                  @click="$refs.LayoutModal.close()">
                  Cancelar
                </v-btn>

                <v-btn
                  large
                  color="#5FD3C7"
                  class="black--text rounded-lg"
                  width="200"
                  @click="updateChecklist">
                  Salvar
                </v-btn>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </Modal>

    <CommentModal
      ref="CommentModal"
    />

    <CriticsModal
      ref="CriticsModal"
    />

    <NewDocumentModal
      ref="NewDocumentModal"
      @updateNewDocument="updateNewDocument($event)"
      :isMultiple="true"
    />
  </div>
</template>

<script>
/* eslint-disable */
import ProgressLoader from '../../components/structure/progressLoader.vue';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import StatusBadge from '../../components/structure/statusBadge.vue';
import UserMessage from '../../components/structure/userMessage.vue';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
import TextField from '../../components/structure/textField.vue';
import DocumentService from '@/services/odoo/documentService';
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import ModalContentTable from './modalContentTable.vue';
import NewDocumentModal from './newDocumentModal.vue';
import CriticsModal from './criticsModal.vue';
import CommentModal from './commentModal.vue';
import ActionBtn from './actionBtn.vue';
import { mapGetters } from 'vuex';
import { formrules } from '../../common/formrules';

export default {
  name: "BeneficiariesTable",

  components: {
    SendDocumentsTable,
    ModalContentTable,
    NewDocumentModal,
    ProgressLoader,
    CriticsModal,
    CommentModal,
    FileDropzone,
    StatusBadge,
    UserMessage,
    ActionBtn,
    TextField,
    Modal,
  },

  data: () => ({
    formrules,
    model: [],
    familyGroup: [],
    documents: [],
    familyGroup: [],
    beneficiaries: [],
    documents: [],
    checklistPayload: [],
    checklistDocuments: [],
    documentService: null,
    creatingFamilyGroup: false,
    carrierCheckListId: null,
    loadingFamilyGroupModal: false,
  }),

  mixins: [
    DocumentsMixins,
    AttachmentMixin,
  ],

  methods: {
    removeDependent() {
      console.log('removeDependent')
    },

    openNewDocumentModal() {
      let documentTypes = [];

      this.familyGroup.beneficiaryDTOs.forEach((beneficiaryDTO) => {
        beneficiaryDTO.documentDTOS.forEach((documentDTO) => {
          documentTypes.push(documentDTO)
        })
      })

      const uniqueDocumentTypes = documentTypes.filter((item, index, self) =>
        index === self.findIndex((obj) => obj.id === item.id)
      );

      this.$refs.NewDocumentModal.open(uniqueDocumentTypes);
    },

    closeFamilyGroupModal() {
      this.$refs.NewDocumentModal.close();

      this.cleanFamilyGroup();
    },

    async getChecklistDocuments() {
      await this.documentService.GetChecklistDocuments()
        .then((response) => response.data)
        .then((result) => this.checklistDocuments = result);
    },

    addFinancialGroup() {
      this.creatingFamilyGroup = true;

      const holderObj = {
        beneficiaryName: null,
        documentDTOS: this.checklistDocuments,
        holder: true,
      };


      this.familyGroup = {
        beneficiaryDTOs: [holderObj],
        holderName: null,
        quantityBeneficiaries: 0,
      };

      this.openFinancialGroup();
    },

    openModal(item) {
      this.title = item.carrierName;

      this.carrierCheckListId = item.carrierCheckListId;

      if (item.familyGroupDTOS && item.familyGroupDTOS.length > 0) {
        this.beneficiaries = item.familyGroupDTOS;

        this.$refs.BeneficiariesModal.open();
      } else {
        this.beneficiaries = item.documentDTOS;

        this.$refs.Layout.open();
      }
    },

    setLoader(familyGroup) {
      let statusCompleted = [];
      let statusTotal = [];

      familyGroup.forEach((beneficiary) => {
        beneficiary.beneficiaryDTOs.forEach((beneficiary) => {
          const status = beneficiary.documentDTOS.map((item) => item.statusCode)
          const statusCodes = beneficiary.documentDTOS.filter((document) => document.statusCode === "completed").map((item) => item.statusCode)

          statusTotal = [...statusTotal, ...status];
          statusCompleted = [...statusCompleted, ...statusCodes];
        })
      });

      const amount = statusCompleted && statusCompleted.length > 0 ? statusCompleted.length : 0;

      return (amount / statusTotal.length) * 100;
    },

    getDocumentSvg() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/download-document.svg" ;
    },

    getFamilyDocuments(family) {
      let familyGroup = [];

      family.beneficiaryDTOs.forEach((beneficiary) => {
        familyGroup = [...familyGroup, ...beneficiary.documentDTOS]
      });

      return familyGroup;
    },

    openCommentModal(item) {
      this.$refs.CommentModal.open(item);
    },

    openCriticsModal(item) {
      let riskDocuments = [];

      if (item.beneficiaryDTOs && item.beneficiaryDTOs.length > 0) {
        item.beneficiaryDTOs.forEach((beneficiaryDTO) => {
          beneficiaryDTO.documentDTOS.forEach((documentDTO) => {
            riskDocuments.push(documentDTO)
          })
        })
      }

      this.$refs.CriticsModal.open(riskDocuments);
    },

    hasBeneficiaryRiskDocumentsValidation(item) {
      let riskDocuments = [];

      if (item.beneficiaryDTOs && item.beneficiaryDTOs.length > 0) {
        item.beneficiaryDTOs.forEach((beneficiaryDTO) => {
          beneficiaryDTO.documentDTOS.forEach((documentDTO) => {
            if (documentDTO.statusCode === 'risk') {
              riskDocuments.push(documentDTO)
            }
          })
        })
      }

      return riskDocuments.length;
    },

    openFinancialGroup() {
      this.$refs.FinancialGroupModal.open();
    },

    closeFinancialGroup() {
      this.$refs.FinancialGroupModal.close();
    },

    editFamilyGroup(item) {
      this.familyGroup = item;

      this.openFinancialGroup();
    },

    updateHolderName() {
      const beneficiaryIndex = this.familyGroup.beneficiaryDTOs.findIndex(
        (beneficiary) => beneficiary.holder === true);

      this.familyGroup.beneficiaryDTOs[beneficiaryIndex].beneficiaryName = this.familyGroup.holderName;
    },

    async setFamilyGroup(item) {
      if(!this.$refs.FamilyGroupForm.validate()) return;

      this.loadingFamilyGroupModal = true;

      if (this.creatingFamilyGroup) {
        const payload = await this.setCreateFamilyGroupPayload(item);

        await this.documentService.CreateFamilyGroup(payload)
          .then((response) => this.closeFinancialGroup())
          .finally(() => this.loadingFamilyGroupModal = false);

      } else {
        const newDependents = item.beneficiaryDTOs.filter(
          (beneficiary) => beneficiary.documentDTOS.some(
            (document) => document.checklist_level_code
          )
        );

        const payload = await this.setUpdateFamilyGroupPayload(item, newDependents);

        const beneficiariesWithFiles = item.beneficiaryDTOs.filter(
          (beneficiary) => beneficiary.documentDTOS.some(
            (document) => document.fileContent));

        let payloadUploadDocuments = []

        for (let beneficiary of beneficiariesWithFiles) {
          for (let checklist of beneficiary.documentDTOS) {
            if(checklist.fileContent || checklist.documentValue) {
              let objUpload = {
                id: checklist.id,
              }

              if (checklist.fileContent) {
                const attachment = await this.mountAttachment(checklist.fileContent);

                objUpload.fileContent = attachment.datas;
                objUpload.fileName = attachment.name;
              } else {
                objUpload.documentValue = checklist.documentValue
              }

              payloadUploadDocuments.push(objUpload);
            }
          }
        }

        await this.documentService.UpdateChecklist(payloadUploadDocuments);

        await this.documentService.AddNewBeneficiary(payload)
          .then((response) => this.closeFinancialGroup())
          .finally(() => this.loadingFamilyGroupModal = false);
      }
    },

    async setUpdateFamilyGroupPayload(item, newDependents) {
      let beneficiaries = [];

      for (const beneficiary of newDependents) {
        const beneficiariesPayload = await this.setBeneficiariesPayload(beneficiary);

        beneficiaries.push(beneficiariesPayload)
      }

      return {
        beneficiary_holder_name: item.holderName,
        quantity_beneficiary: item.beneficiaryDTOs.length,
        deployment_checklist_id: this.carrierCheckListId,
        deployment_document_by_beneficiary_ids: beneficiaries,
      }
    },

    async setCreateFamilyGroupPayload(item) {
      let beneficiaries = [];

      for (const beneficiary of item.beneficiaryDTOs) {
        const beneficiariesPayload = await this.setBeneficiariesPayload(beneficiary);

        beneficiaries.push(beneficiariesPayload);
      }

      return {
        beneficiary_holder_name: item.holderName,
        quantity_beneficiary: item.beneficiaryDTOs.length,
        deployment_checklist_id: this.carrierCheckListId,
        deployment_document_by_beneficiary_ids: beneficiaries,
      };
    },

    async setBeneficiariesPayload(beneficiaryDTO) {
      let documents =  [];

      for (const documentDTO of beneficiaryDTO.documentDTOS) {
        const documentPayload = await this.setDocumentsPayload(documentDTO);

        documents.push(documentPayload)
      }

      return {
        beneficiary_name: beneficiaryDTO.beneficiaryName,
        holder: beneficiaryDTO.holder,
        deployment_document_ids: documents,
      };
    },

    async setDocumentsPayload(documentDTO) {
      let fileContent = null;

      if (documentDTO.fileContent) {
        const attachment = this.mountAttachment(documentDTO.fileContent);
        fileContent = await Promise.all([attachment]);

        return {
          attachment_ids: fileContent,
          document_type_id: {
            id: documentDTO.id,
          },
        };
      } else {
        return {
          document_value: documentDTO.documentValue,
          document_type_id: {
            id: documentDTO.id,
          }
        };
      }
    },

    setDocumentValue(item, documentValue) {
      item.fileContent = null;
      item.documentValue = documentValue;
    },

    async updateChecklist() {
      for (let checklist of this.checklistPayload) {
        if (checklist.fileContent) {
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      this.loading(true);

      await this.documentService.UpdateChecklist(this.checklistPayload)
        .finally(() => this.loading(false));
    },

    setFile(item, file) {
      item.documentValue = null;
      item.fileContent = file;
    },

    setDocumentTypeFieldMask(item) {
      if (!item.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'smartphone':
          return  "(##) #####-####";
        default:
          return null
      }
    },

    setTitle(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    setRuleTypes(item) {
      if (!item.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'email':
          return  [this.formrules.email(item.documentValue)];

        case 'smartphone':
          return  [this.formrules.cellphone(item.documentValue)];

        default:
          return [true];
      }
    },

    setTextFieldMask(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    cleanFamilyGroup() {
      this.creatingFamilyGroup = false;
      this.familyGroup = null;
    },

    async updateNewDocument(documents) {
      for (let checklist of documents) {
        if (checklist.fileContent && Array.isArray(checklist.fileContent)) {
          checklist.filesToUpload = [];

          for (let document of checklist.fileContent) {
            const attachment = await this.mountAttachment(document.fileContent);

            checklist.filesToUpload.push(attachment.datas);
          }
        } else {
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      this.loading(true);

      await this.documentService.UpdateChecklist(documents)
        .finally(() => this.loading(false));
    },

    addDependent() {
      const newDependent = this.setNewDependent();

      this.familyGroup.beneficiaryDTOs.push(newDependent);

      const beneficiaryIndex = this.familyGroup.beneficiaryDTOs.length-1;

      const beneficiaryRefName = `beneficiary-${beneficiaryIndex}`;

      setTimeout(() => {
        const newBeneficiaryElement = document.getElementById(beneficiaryRefName);

        newBeneficiaryElement.scrollIntoView({ behavior: 'smooth' });
      }, 500)
    },

    setNewDependent() {
      return {
        beneficiaryName: null,
        documentDTOS: this.checklistDocuments,
        holder: false,
      }
    }
  },

  computed: {
    ...mapGetters(["getChecklist"]),

    beneficiariesChecklist() {
      const beneficiaries = this.getChecklist.find((checklist) => checklist.checklistLevelCode === 'RECIPIENT_DOC');
      return beneficiaries.levelDTOS && beneficiaries.levelDTOS.length > 0 ? beneficiaries.levelDTOS : [];
    },

    config() {
      return {
        headers: [
          {
            text: 'Benefício',
            value: 'benefit',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Nº de anexos',
            value: 'attachmentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Preenchimento',
            value: 'fill',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    totalBeneficiaies() {
      let amount = 0;

      this.beneficiariesChecklist.forEach((checklist) => {
        checklist.familyGroupDTOS.forEach((familyGroup) => {
          amount += familyGroup.quantityBeneficiaries;
        })
      });

      return amount;
    },

    modalConfig() {
      return {
        headers: [
          {
            text: 'Titular',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de Dependentes',
            value: 'dependentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    layoutConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de Dependentes',
            value: 'dependentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 1,
              xl: 1,
            }
          },
        ]
      }
    },

    layoutConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Modelo',
            value: 'model',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 1,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }
          },
        ]
      }
    },
  },

  created() {
    this.documentService = new DocumentService();
  },

  async mounted() {
    await this.getChecklistDocuments()
  },
}
</script>
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-0",attrs:{"fluid":""}},[_c(VDataTable,_vm._g(_vm._b({attrs:{"fixed-header":"","hide-default-header":"","min-height":"300","page":_vm.page,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":_vm.items.length === 0
      || _vm.config.hideDefaultFooter
      && _vm.config.hideDefaultFooter === true,"loading-text":_vm.loadingMessage,"footer-props":{
      disablePagination: _vm.loading,
    },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return [_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex align-center rounded-lg py-0",staticStyle:{"min-height":"60px","background-color":"#5555A5 !important","margin":"0 1px"}},_vm._l((props.headers),function(header,index){return _c(VCol,_vm._b({key:index,class:`d-flex justify-${header.align}`,on:{"click":function($event){header.sortable === true ? _vm.toggleSort(header.value) : false}}},'v-col',header.attrs,false),[_c('label',{staticClass:"text-subtitle-1 white--text font-weight-bold d-flex align-center",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(header.text)+" "),(_vm.sortBy === header.value && header.sortable === true)?_c(VIcon,{staticClass:"ml-2",attrs:{"size":"16","color":_vm.arrowColor}},[_vm._v(" "+_vm._s(_vm.sortDesc ? 'fas fa-arrow-down' : 'fas fa-arrow-up')+" ")]):_vm._e()],1)])}),1)],1)]}},{key:"item",fn:function(props){return [_c(VContainer,{staticClass:"py-1 px-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"rounded-lg elevation-2 d-flex align-center",staticStyle:{"margin":"0 1px","min-height":"60px"}},_vm._l((props.headers),function(header,i){return _c(VCol,_vm._b({key:i,class:`d-flex justify-${header.align}`},'v-col',header.attrs,false),[_vm._t(`item.${header.value}`,null,null,props)],2)}),1)],1)]}}],null,true),model:{value:(_vm.localModel),callback:function ($$v) {_vm.localModel=$$v},expression:"localModel"}},'v-data-table',_vm.config,false),_vm.config.on))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Modal
    ref="modal"
    :title="title"
    :useBackBtn="true"
    :useCloseBtn="false">
    <template
      slot="body">
      <v-row
        style="
          min-height: 60px;
          background-color: #F6F2FC !important;
          margin: 0 1px;"
        class="py-0 px-10">
        <v-col
          class="d-flex align-center justify-start">
          <label
            class="text-subtitle-1 black--text">
            Nome: {{item.name}}
          </label>
        </v-col>
        <v-col
          class="d-flex align-center justify-center">
          <label
            class="text-subtitle-1 black--text">
            Anexo:
          </label>

          <v-icon
            class="ml-2"
            color="#5FD3C7"
            size="20">
            fas fa-paperclip
          </v-icon>
        </v-col>
        <v-col
          class="d-flex align-center justify-end">
          <!-- <label
            class="text-subtitle-1 black--text">
            Enviado: 12/01/2024
          </label> -->
        </v-col>
      </v-row>

      <div
        style="min-height: 100px; max-height: 500px; overflow: auto;"
        class="pb-0">
        <v-container>
          <v-row>
            <v-col
              cols="4"
              v-if="isCombo">
              <label
                style="font-size: 14px; color: #808080;">
                Tipo de documento *
              </label>

              <v-autocomplete
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              v-else>
              <label
                style="font-size: 14px; color: #808080;">
                Comentário*
              </label>

              <v-textarea
                outlined
                v-model="comment"
                hide-details
                width="100%"
              />
            </v-col>

            <v-col
              cols="12"
              class="my-3 pr-6">
              <v-row
                class="d-flex justify-end">
                <v-btn
                  large
                  color="#5FD3C7"
                  class="black--text rounded-lg"
                  width="200"
                  @click="addComment"
                  :disabled="!comment">
                  Salvar
                </v-btn>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              style="max-height: 200px; overflow-y: auto;">
              <label
                style="font-size: 14px;"
                class="mr-2 font-weight-bold">
                Histórico
              </label>

              <div
                v-for="item in comments"
                :key="item.id">
                <v-sheet
                  color="#F0F0F0"
                  class="pa-2 elevation-2 rounded-lg mb-2"
                  min-height="140">
                  <v-container>
                    <v-row
                      no-gutters>
                      <v-col
                        style="max-width: 50px;">
                        <v-sheet
                          height="40"
                          width="40"
                          color="#5FD3C7"
                          class="rounded-circle d-flex justify-center align-center elevation-2">
                          <span
                            class="text-h6 font-weight-bold">
                            FM
                          </span>
                        </v-sheet>
                      </v-col>
                      <v-col
                        cols="10"
                        class="d-flex flex-column justify-start">
                        <label
                          class="font-weight-bold my-2"
                          style="font-family: 16px;">
                          {{item.userName}}
                        </label>

                        <div v-html="formatComment(item.comment)"></div>

                        <p
                          style="color: #808080;">
                          {{moment(item.date.replace(" ", "T") + '.000Z').format("DD/MM/YYYY HH:mm")}}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/structure/modal.vue';
import DocumentService from '@/services/odoo/documentService.js';
import { mapMutations } from 'vuex';
import moment from 'moment';
export default {
  name: "CommentModal",

  components: {
    Modal,
  },

  props: {
    title: {
      type: String,
      default: "'Novo Documento'"
    },
    isCombo: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    moment,
    comments: [],
    comment: null,
    item: null,
    documentService: null,
  }),

  methods: {
    ...mapMutations(["loading"]),

    async open(item) {
      this.item = item;
      await this.getComments()
      this.$refs.modal.open();
      this.loading(false);
    },

    async addComment() {
      this.loading(true);
      await this.documentService
        .AddComment({
          comment: this.comment,
          userName: this.userName,
          parentEntityId: this.item.id
        })
        .then(async (response) => {
          this.comment = null;
          await this.getComments()
        });
    },

    async getComments() {
      this.loading(true);
      await this.documentService.GetCommentsByDocumentId(this.item.id)
        .then((response) => response.data)
        .then((result) => this.comments = result)
        .finally(() => this.loading(false));
    },

    formatComment(comment) {
      return comment.replace(/\n/g, '<br>');
    }
  },

  created() {
    this.documentService = new DocumentService();
  },

  mounted() {
    this.userName = this.$util.getSession()
      && this.$util.getSession().name
        ? this.$util.getSession().name
        : null;
  },
}
</script>
import RequestMixin from "./requestMixin";
import { mapMutations } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    userEmail: null,
  }),
  mixins: [
    RequestMixin,
  ],

  methods: {
    ...mapMutations(["loading", "showmsg"]),

    async createCase() {
      if (!this.$refs.NewTicketForm.validate()) return;

      let configuredCaseData = this.mountCaseData();
      let payload = await this.mountPayload(configuredCaseData);

      await this.createCaseWithAttachment(payload);
    },

    async createCaseEvent() {
      let payload = {
        entityId: null,
        caseComment: {
          name: null,
          res_id: null,
          body: null,
          model: null,
          message_type: null,
          email_from: this.userEmail,
        },
        files: []
      }

      let emptyFile = false;
      let emptyFileNames = "";

      const files = this.currentCase.files.map((file) => {
        let fileName = file.name;

        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames += file.name + "<br/>"
        }

        return {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };
      });

      if( emptyFile ) {
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        throw "Empty File"
      }

      payload.entityId = this.currentCase.id;
      payload.caseComment.body = this.currentCase.message;
      payload.caseComment.message_type = "comment";
      payload.caseComment.model = "helpdesk.ticket";
      payload.caseComment.name = moment(new Date()).format("DD/MM/YYYY - HH:mm");
      payload.caseComment.res_id = this.currentCase.id;
      payload.files = files;

      this.loading(true);

      await this._caseService.CreateCaseEvent(payload)
        .then(async () => {
          await this.loadServiceComents(this.currentCase)
        })
        .catch((err) => this.showmsg(err))
        .finally(() => {
          this.resetCurrentCaseForm();
          this.loading(false)
        });
    },

    async createCaseWithAttachment(payload) {
      this.loading(true);

      await this._caseService
        .CreateCaseWithAttachment(payload)
        .then(async (response) => {
          this.resetTicketFlow();
          this.tickets = await this.getImplantationCase();
        })
        .catch((err) => this.showmsg(err))
        .finally(() => this.loading(false));
    },

    mountCaseData() {
      return {
        name: this.ticket.solicitation.name,
        xipp_contract_id: {
          id: this.ticket.contract.id,
        },
        partner_id: {
          id: this.ticket.contract.contract_owner_id.id,
        },
        visible_to_the_customer: true,
        description: this.ticket.description,
        ticket_type_id: this.ticket.solicitation.code,
        xipp_origin_id: "rh_protegido",
        xipp_attendee_name: "",
        xipp_viewer_permission_type_id: { "code": "m" },
        email_from: this.userEmail,
      }
    },

    async mountPayload(configuredCaseData) {
      const attachments = this.ticket.files.map(
        (attachment) => this.mountAttachment(attachment)
      );

      const files = await Promise.all(attachments);

      return {
        caseObject: configuredCaseData,
        files: files,
        usersPermissionView: [],
      }
    },

    resetTicketFlow() {
      this.showTicketForm = false;

      this.resetTicketForm();
    },

    resetCurrentCaseForm() {
      this.currentCase.files = [];
      this.currentCase.message = "";
    },

    resetTicketForm() {
      this.ticket = {
        solicittation: null,
        contract: {},
        description: null,
        files: [],
      };
    },
  },

  mounted() {
    this.userEmail = this.$util.getSession() && this.$util.getSession().email
      ? this.$util.getSession().email
      : null;
  },
}
<template>
  <v-container
    fluid>
    <v-row>
      <v-col
        v-for="(step, stepIndex) in steps"
        :key="`${stepIndex}-step`"
        class="pa-0 d-flex flex-column justify-center align-center"
        style="width: 100%;">
        <div
          style="height: 60px; position: relative; width: 100%;"
          class="d-flex flex-row">
          <v-sheet
            height="60"
            width="60"
            class="d-flex justify-center align-center rounded-circle"
            :style="stepValidation(step, stepIndex) ? 'border: 3px solid #60D3C7;' : 'border: 3px solid #E0E0E0 !important;'"
            :color="stepValidation(step, stepIndex) ? '#D8EAE8' : 'white'"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;">
            <v-img
              height="34"
              max-height="34"
              max-width="34"
              contain
              :src="setStepperIcon(stepValidation(step, stepIndex) ? step.code : `${step.code}-alt`)"
            />
          </v-sheet>

          <v-divider
            v-if="stepIndex < steps.length -1"
            :style="`
              width: 100%;
              z-index: 1;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(0%) translateY(-50%);
              border-width: medium;
              border-color: ${stepBarValidation(step) ? '#60D3C7' : '#E0E0E0'};`"
          />
        </div>

        <div
          style="height: 160px;">
          <p
            class="font-weight-medium text-center mb-4"
            :style="`color: ${stepValidation(step, stepIndex) ? 'black' : '#808080'}; height: 50px;`">
            {{ step.name }}
          </p>

          <div
            class="px-2 d-flex flex-column"
            v-if="
              calculateCompletedTasks(step.tasks) !== calculateTotalTasks(step.tasks)
              && stepValidation(step, stepIndex)">
            <ProgressLoader
              :loaded="calculeTasksPercentage(step.tasks)"
              :inner="true"
            />

            <div
              style="min-height: 32px;">
              <v-btn
                large
                block
                style="max-height: 44px;"
                color="#5555A5"
                class="rounded-lg mt-2 white--text"
                v-if="stepValidation(step, stepIndex)"
                @click="goToSendDocuments">
                <span
                  class="text-capitalize text-subtitle-1">
                  Enviar Documentos
                </span>
              </v-btn>
            </div>
          </div>

          <div
            v-else
            class="d-flex justify-center align-end pt-2">
            <span
              style="color: #808080;">
              {{(calculateCompletedTasks(step.tasks))}}/{{calculateTotalTasks(step.tasks)}}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import ProgressLoader from './progressLoader.vue';
import ImplantationMixins from '../../shared/mixins/implantationMixins';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
export default {
  name: "Stepper",

  components: {
    ProgressLoader,
  },

  mixins: [
    ImplantationMixins,
    DocumentsMixins,
  ],

  props: {
    onlySteps: {
      type: Boolean,
      default: false,
    },

    steps: {
      type: Array,
      required: true,
    }
  },

  methods: {
    goToSendDocuments() {
      this.$router.push("/envioDocumentos");
    },

    stepValidation(step, stepIndex) {
      if (stepIndex === 0) return true;

      const validStepIndices = this.steps
        .filter((step) => this.calculeTasksPercentage(step.tasks) === 100)
        .map((item, index) => index);

      const currentStepPercentage = this.calculeTasksPercentage(this.steps[stepIndex].tasks);

      if (validStepIndices.includes(stepIndex)) return true;

      return currentStepPercentage === 100 ? true : false;
    },

    stepBarValidation(step) {
      return this.calculeTasksPercentage(step.tasks) === 100
    },
  },
}
</script>

<style>
.custom-stepper > .v-stepper__header > .v-stepper__step > .v-stepper__step__step {
  display: none !important;
}
</style>

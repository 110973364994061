const formrules = {
  maxLength10: value => value && value.length <= 10 || "Máximo 10 caracteres.",
  required: value => !!value || "Preenchimento obrigatório.",
  valueGreaterThanZero: value => (!value || (!!value && value > 0)) || "O valor deve ser maior que zero.",
  valueGreaterEqualsThanZero: value => (!value || (!!value && value >= 0)) || "O valor deve ser maior ou igual a zero.",
  cellphone: value => (value && value.length === 15) || '*Número inválido',
  email: value => {
    if (value && value.length > 0) {
      const pattern = /^(([^<>(){}!#$%^=+*[\]\\.,;:\s@"]+(\.[^<>(){}!#$%^=+*[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'E-mail inválido';
    }

    return true;
  }
}

export {
  formrules
}
import {
  loginSecurityService , getCurrentUser, getMenu, getProfilePicture,
  findFinancialGroupById, logoutSession, tratarMensagemErroLogin, patchCurrentUser  } from '@/common/securityserver';
//import $router from "../router/router";
const SESSION_KEY_NAME = 'auth_user';

const state = {
  session: {},
  doneTour: false,
  userProfilePicture: "https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png",
};

const getters = {
  user: (state) => state.session,
  getDoneTour: (state) => state.doneTour,
  userProfilePicture: (state) => state.userProfilePicture,
};

const actions = {
  async login(context, data) {

    const tokenSecurityService = await loginSecurityService(data);

    if (!tokenSecurityService) {
      return { error: true, text: 'Acesso invalido' };
    }else{
      return await context.dispatch('createSession', tokenSecurityService, data);
    }
  },
  async createSession(context, tokenSecurityService, loginUser){
    let loginList = []
    if (tokenSecurityService.returnLoginAs == true) {
      loginList.push(patchCurrentUser(tokenSecurityService))
    } else {
      loginList.push(getCurrentUser(tokenSecurityService))
    }
    loginList.push(getProfilePicture(tokenSecurityService))
    loginList.push(getMenu(tokenSecurityService))
    const [currentUser, profilePicture, menuData] = await Promise.all(
      loginList
    );
    localStorage.removeItem('LOCALIZED_TOKEN');
    if(currentUser && currentUser.message){
      let error_message = tratarMensagemErroLogin(currentUser.message);
      const iframe = document.getElementById("accountIframe");
      if(error_message === 'O Termo de Uso não foi aceito.') {
        iframe.contentWindow.postMessage('redirectToTerms', "*");
        // eslint-disable-next-line no-dupe-else-if
      } else if(error_message === 'ACCESS_VALIDATION_ERROR_MESSAGE') {
        iframe.contentWindow.postMessage('accessViolation', "*");
      }
      return currentUser ;
    }

    const user = currentUser && currentUser.user;
    const loggedBy = currentUser && currentUser.loggedBy;
    const authorities = currentUser && currentUser.authorities;
    user.tokenSecurityService = tokenSecurityService;

    const financialGroup = await findFinancialGroupById(user.financialGroupExternalID, tokenSecurityService);

    if(loggedBy) {
      loggedBy.token = context.state.session.tokenSecurityService.access_token;
      loggedBy.tinnyToken = context.state.session.tokenSecurityService.tinnyToken;
    }

    const formattedSessionData = {
      name: user.name,
      email: user.email,
      profiles: user.profiles,
      tokenSecurityService: user.tokenSecurityService,
      authorities: authorities,
      financialGroup: {
        id: financialGroup.id,
        name: financialGroup.name,
      },
      loggedBy: loggedBy,
      dtcriacao: new Date().getTime(),
      menu: menuData
    };

    localStorage.setItem(SESSION_KEY_NAME, JSON.stringify(formattedSessionData));

    context.commit('setSession', formattedSessionData);
    context.commit('setUserProfilePicture', profilePicture.imgContent);
  },

  async startSession(context, sessionData) {
    const formattedUser = {
      nome: sessionData.name,
      email: sessionData.email,
      profiles: sessionData.profiles,
      authorities: sessionData.authorities,
      dtcriacao: new Date().getTime(),
      tokenSecurityService: sessionData.tokenSecurityService,
      menu: await getMenu(sessionData.tokenSecurityService),
      financialGroup: sessionData.financialGroup,
      loggedBy: sessionData.loggedBy,
    };

    localStorage.setItem(SESSION_KEY_NAME, JSON.stringify(formattedUser));

    context.commit('setSession', formattedUser);
  },
  logout(context) {
    const user = JSON.parse(localStorage.getItem(SESSION_KEY_NAME) || '{}');
    if(user && user.tokenSecurityService)
      logoutSession(user.tokenSecurityService);
    localStorage.removeItem(SESSION_KEY_NAME);
    context.commit('updateMenuState', {
      left: false,
      leftmini: false,
      right: false,
    });
    context.commit('setSession', {});
  },
  checkAuthentication(context) {
    const user = JSON.parse(localStorage.getItem(SESSION_KEY_NAME) || '{}');
    const creationDate = user.dtcriacao;

    if (!creationDate) {
      return false;
    }
    context.commit('setSession', user);
    const sessionTolerance = 3600000;
    const difference = new Date().getTime() - creationDate;
    return difference <= sessionTolerance;
  },
};

const mutations = {
  setDoneTour(state, data) {
    state.doneTour = data;
  },

  setSession(state, data) {
    state.session = data;
  },

  setUserProfilePicture(state, picture) {
    if (picture){
      state.userProfilePicture = `data:image;base64,${picture}`;
    } else {
      state.userProfilePicture = "https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png";
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

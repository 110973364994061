export default {
  methods: {
    calculePercentage(proposal) {
      return (this.calculateCompletedTasksInProposal(proposal) / this.calculateTotalTasksInProposal(proposal)) * 100;
    },

    calculateCompletedTasksInProposal(proposal) {
      let completedTasks = 0;

      proposal.steps.forEach(
        (step) => {
          completedTasks += step.tasks.filter((task) => task.status === "Concluído").length;
        });

      return completedTasks;
    },

    calculateTotalTasksInProposal(proposal) {
      let completedTasks = 0;

      proposal.steps.forEach(
        (step) => {
          completedTasks += step.tasks.length;
        });

      return completedTasks;
    },

    calculateCompletedTasks(tasks) {
      return tasks.filter((task) => task.status === 'Concluído').length;
    },

    calculateTotalTasks(tasks) {
      return tasks.length;
    },

    calculeTasksPercentage(tasks) {
      return ((this.calculateCompletedTasks(tasks) / this.calculateTotalTasks(tasks)) * 100);
    },
  }
}
export default {
  methods: {
    setStepperIcon(code) {
      return `/img/icons/` + process.env.VUE_APP_ACCOUNT_APP_CODE + `/${code}.svg`;
    },

    setStatusColor({ label, code, color }) {
      const statusMapping = {
        completed: { color: "#CDF9D9", labelColor: "#154B24", label: "Concluído" },
        pending: { color: "#E0EFFF", labelColor: "#14497C", label: "Pendente" },
        risk: { color: "#F7BEB8", labelColor: "#7C1C0F", label: "Crítica" },
        digital: { color: "#E0EFFF", labelColor: "#14497C", label: "Digitalização" },
        cancel: { color: "#FAEEBD", labelColor: "#866113", label: "Cancelado" },
        analysis: { color: "#E0EFFF", labelColor: "#14497C", label: "Em análise" },
      };

      if (code && statusMapping[code]) {
        return statusMapping[code].color;
      }

      if (label) {
        const match = Object.values(statusMapping).find(
          (status) => status.label === label
        );
        return match ? match.color : statusMapping.pending.color;
      }

      if (color) {
        const match = Object.values(statusMapping).find(
          (status) => status.color === color
        );
        return match ? match.labelColor : statusMapping.pending.labelColor;
      }

      return statusMapping.pending.color;
    },

    countBeneficiaryFiles(familyGroup) {
      let count = 0;

      familyGroup.familyGroupDTOS.forEach((beneficiary) => {
        beneficiary.beneficiaryDTOs.forEach((beneficiary) => {
          const files = beneficiary.documentDTOS.map((item) => item.statusCode)

          count = count + files.length;
        })
      });

      return count;
    },

    setHeathStatus(familyGroup) {
      let label = "Pendente";
      let color = this.setStatusColor({code: 'pending'});

      let documentStatus = [];

      familyGroup.familyGroupDTOS.forEach((familyGroupDTO) => {
        familyGroupDTO.beneficiaryDTOs.forEach((beneficiary) => {
          const status = beneficiary.documentDTOS.map((item) => item.statusCode);

          documentStatus = [...documentStatus, ...status];
        })
      });

      let res = this.setFamilyGroupStatus(documentStatus)

      label = res.label;
      color = res.color;

      return {
        label: label,
        color: color
      }
    },

    setBeneficiaryHeathStatus(familyGroupDTO) {
      let label = "Pendente";
      let color = this.setStatusColor({code: 'pending'});

      let documentStatus = [];

      familyGroupDTO.beneficiaryDTOs.forEach((beneficiary) => {
        const status = beneficiary.documentDTOS.map((item) => item.statusCode);

        documentStatus = [...documentStatus, ...status];
      });

      let res = this.setFamilyGroupStatus(documentStatus)

      label = res.label;
      color = res.color;

      return {
        label: label,
        color: color,
      }
    },

    setFamilyGroupStatus(documentStatus) {
      let label = null;
      let color = null;

      if (documentStatus.includes('cancel')) {
        label = "Cancelado";
        color = this.setStatusColor({code: 'cancel'});
      }

      if (documentStatus.includes('digital')) {
        label = "Digitalização";
        color = this.setStatusColor({code: 'digital'});
      }

      if (documentStatus.includes('completed')) {
        label = "Concluído";
        color = this.setStatusColor({code: 'completed'});
      }

      if (documentStatus.includes('analysis')) {
        label = "Em Validação";
        color = this.setStatusColor({code: 'analysis'});
      }

      if (documentStatus.includes('pending')) {
        label = "Pendente";
        color = this.setStatusColor({code: 'pending'});
      }

      if (documentStatus.includes('risk')) {
        label = "Com crítica";
        color = this.setStatusColor({code: 'risk'});
      }

      return {
        label: label,
        color: color,
      }
    },

    setTemplateStatus(item) {
      let label = "Pendente";
      let color = this.setStatusColor({code: 'pending'});

      const documentStatus = item.documentDTOS.map((document) => document.statusCode);

      let res = this.setFamilyGroupStatus(documentStatus)

      label = res.label;
      color = res.color;

      return {
        label: label,
        color: color
      }
    },
  }
}
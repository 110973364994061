import ContentVersionService from "@/services/odoo/ContentVersionService";
import TicketTypeService from '@/services/odoo/TicketTypeService';
import ContractService from "@/services/odoo/ContractService";
import ComentsService from "@/services/odoo/ComentsService";
import CaseService from "@/services/odoo/CaseService";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    _contentVersionService: null,
    _ticketTypeService: null,
    _contractService: null,
    _comentsService: null,
    _caseService: null,
  }),

  methods: {
    ...mapMutations(["showmsg", "loading"]),

    async getTicketTypes() {
      return await this._ticketTypeService.FindTicketType()
        .then((response) => response.data)
        .then((result) => result)
        .catch((err) => this.showmsg(err));
    },

    async getImplantationCase() {
      const params = new URLSearchParams();
      params.append('isImplantation', 'true');

      return await this._caseService.FindByIdV2(params)
        .then((response) => response.data.content)
        .then((result) => result);
    },

    async getSupportTeam() {
      return await this._contractService.GetSupportTeam()
        .then((response) => response.data)
        .then((result) => result);
    },

    async getContracts() {
      return await this._contractService.FindImplantationContracts()
        .then((res) => res.data)
        .then((result) => result);
    },

    async loadServiceComents(ticket){
      this.loading(true);

      await this._comentsService.FindById(ticket.id, 'helpdesk.ticket', 'comment', 'Discussões')
        .then((response) => response.data)
        .then((result) => {
          const historic = result.records.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));
          this.serviceHistoric = historic;

          setTimeout(() => {
            const element = document.getElementById("serviceHistoricBody");
            element.scrollIntoView({ behavior: "smooth", block: "end" });
            element.scrollTop = element.scrollHeight;
          }, 500)
        })
        .finally(() => this.loading(false));
    },
  },

  created() {
    this._contentVersionService = new ContentVersionService();
    this._ticketTypeService = new TicketTypeService();
    this._contractService = new ContractService();
    this._comentsService = new ComentsService();
    this._caseService = new CaseService();
  },
}
<template>
  <v-container style="overflow: auto !important;" fluid>
    <v-form>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="mb-5">
            <v-card-text>
              <p class="display-1 text--primary text">
                {{ timeline.internalDescription ? 'Título: ' + timeline.internalDescription : 'Título: '}} <small
                  class="float-right"><i>{{ feedItemStatusLabel(timeline.feedItemStatus) }} </i></small>
              </p>
              <small>Criado em: {{ timeline.creationDate | formatDate }}</small>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-btn
                    large
                    light
                    @click="cancelar()"
                    class="black--text"
                  >{{ !isNewRecord ? "Cancelar" : "Voltar" }}
                  </v-btn>
                </v-col>
                <v-col cols="9" align="right">
                  <v-btn
                    large
                    class="mb-2"
                    color="outline-primary"
                    :disabled="buzy"
                    :loading="buzy"
                    @click="salvarRascunho()"
                  > Salvar como Rascunho
                  </v-btn>
                  <v-btn
                    v-if="this.timeline.feedItemStatus !== 'ARCHIVED' && !isNewRecord"
                    large
                    color="dark"
                    class="ml-2 mb-2"
                    :disabled="buzy"
                    :loading="buzy"
                    @click="salvarArquivar()"
                  > Arquivar
                  </v-btn>
                  <v-btn
                    v-if="!isNewRecord"
                    large
                    class="ml-2 mb-2"
                    color="primary"
                    :disabled="buzy"
                    :loading="buzy"
                    @click="salvarPublicar()"
                  > Publicar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <p>Selecione o Tipo de Publicação</p>
                  <v-radio-group v-if="sfUser" row v-model="timeline.feedItemType" @change="validateAlwaysShow()">
                    <v-radio value="POST" label="Post no Feed"></v-radio>
                    <v-radio value="TIP" label="Dica"></v-radio>
                    <v-radio value="BANNER" label="Banner"></v-radio>
                  </v-radio-group>
                  <v-radio-group v-if="!sfUser" row v-model="timeline.feedItemType">
                    <v-radio checked value="POST" label="Post no Feed"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="6" v-if="sfUser">
                  <p>Contexto da Publicação</p>
                  <v-radio-group row v-model="timeline.applicationFilter">
                    <v-radio value="MUNDO_A_DENTRO" label="Mundo a dentro"></v-radio>
                    <v-radio value="MUNDO_A_FORA" label="Mundo a fora"></v-radio>
                    <v-radio value="TODO_MUNDO" label="Todo mundo"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="4" v-if="timeline.feedItemType !== 'BANNER'">
                  <v-select
                    :items="entityList"
                    v-model="timeline.entity"
                    v-if="timeline.feedItemType !== 'TIP' && timeline.feedItemType !== 'BANNER'"
                    label="Entidade"
                    placeholder="Selecione a entidade"
                    outlined
                  />
                  <v-text-field
                    type="text"
                    v-if="timeline.feedItemType == 'TIP' && !isNewTip"
                    v-model="timeline.category"
                    label="Categoria"
                    outlined
                    placeholder="Categoria da Dica"
                  />
                  <v-text-field
                    type="number"
                    v-if="timeline.feedItemType == 'TIP'"
                    v-model="timeline.feedOrder"
                    label="Ordem"
                    outlined
                    placeholder="Ordem"
                  />
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <v-menu
                    ref="menuPublishDate"
                    v-model="menuPublishDate"
                    :close-on-content-click="false"
                    :return-value.sync="timeline.publishDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        outlined
                        :value="formatPublishDateInput"
                        label="Data de Publicação"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @change="(value) => {$refs.menuPublishDate.save(value); setPublishDate(value)}"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="timeline.publishDate"
                      no-title
                      scrollable
                      @change="() => {$refs.menuPublishDate.save(timeline.publishDate); setPublishDate(timeline.publishDate)}"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <v-menu
                    ref="menuExpirationDate"
                    v-model="menuExpirationDate"
                    :close-on-content-click="false"
                    :return-value.sync="timeline.expirationDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        outlined
                        :value="formatExpirationDateInput"
                        label="Data de Expiração"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @change="(value) => {$refs.menuPublishDate.save(value); setExpirationDate(value)}"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="timeline.expirationDate"
                      no-title
                      scrollable
                      @change="() => {$refs.menuExpirationDate.save(timeline.expirationDate); setExpirationDate(timeline.expirationDate)}"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="timeline.description"
                    label="Descrição (Publicada)"
                    placeholder="Descrição que será publicada"
                    auto-grow
                    outlined
                    hide-details
                    class="pb-5"
                    @focus="showAlert = true"
                  />
                </v-col>

                <v-col cols="12">
                  <v-alert
                      :value="showAlert"
                      transition="scale-transition"
                      outlined
                      color="primary"
                      prominent
                      icon="fa-info">
                    <v-row align="center">
                      <v-col class="grow caption">
                        <v-row class="px-0 mx-0">
                          <v-col cols="12">
                            Você pode personalizar a descrição do seu Post no Feed, utilizando as seguintes hashtags especiais:
                          </v-col>
                        </v-row>

                        <v-row class="px-0 mx-0">
                          <v-col cols="12">
                            <v-chip small color="primary" class="ma-2 text--white" v-for="hashtag in hashtags" :key="hashtag.name">
                              {{ hashtag.name }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon color="primary" @click="showAlert = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="timeline.internalDescription"
                    label="Título"
                    placeholder="Título"
                    auto-grow
                    outlined
                    hide-details
                    class="pb-5"
                  />
                </v-col>

                <v-col cols="12" v-if="timeline.feedItemType === 'BANNER'">
                  <v-checkbox
                    v-model="timeline.alwaysShow"
                    label="Exibição contínua"
                  />
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title class="primary--text">
              {{this.loadingMedia ? 'Carregando Conteúdos...' : 'Conteúdos'}}
              <v-spacer/>
              <v-btn
                fab
                top
                right
                color="primary"
                dark
                @click="addMedia()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="d-flex" cols="12" md="4" sm="6" lg="4" v-for="(media, index) in timeline.medias"
                   :key="'media'+media.id+media.keyGen">
                  <v-card elevation="10" class="flex">
                    <v-card-title class="primary--text">{{ media.type || '[Nova]' }}
                      <v-spacer/>
                      <v-btn fab top right small
                       color="danger"
                       dark
                       @click="removeMedia(index)"
                      >
                        <v-icon>fas fa-times</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-col cols="12">
                      <v-text-field
                        type="text"
                        v-model="media.url"
                        :disabled="(media.id != null && media.id > 0)"
                        label="Url da Media"
                        outlined
                        placeholder="Url da Media"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        v-model="media.mediaOrder"
                        label="Ordem de Apresentação"
                        outlined
                        placeholder="Ordem de Apresentação"
                      />
                    </v-col>
                    <v-col cols="12" v-if="!media.id && !media.image && !media.video">
                      <v-file-input
                        v-model="media.file"
                        @change="setAttachments(media)"
                        truncate-length="15"
                        accept="video/*, image/*"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <iframe
                        :key="index + media.url"
                        v-if="media.url"
                        :src="media.url"
                        class="timeline-midia" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen></iframe>
                    </v-col>
                    <v-col cols="12" v-if="media.image || media.video">
                      <v-img
                        width="100%"
                        v-if="media.image"
                        contain
                        :src="media.image"

                      />
                      <video v-if="media.video" width="100%" height="100%" controls>
                        <source :src="media.video" :type="media.videoType"/>
                        Your browser does not support the video tag.
                      </video>
                    </v-col>
                    <v-col cols="12" v-if="!media.id && (media.image || media.video)">
                      <div>
                        <v-card-actions>
                          <v-btn
                            block
                            large
                            color="secondary"
                            light
                            @click="clearMedia(media)"
                            class="black--text"
                          >Limpar Arquivo
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <targetPublicListar v-if="!isNewRecord" :feed="{ id: timeline.id }" class="mb-5 mt-0 pt-0"/>

          <v-flex class="mt-5" xs12 v-if="!isNewRecord && timeline.feedItemType == 'TIP'">
            <ProgressiveLoader :disabled="disableScroll" @reachedEnd="getTimeline">
              <v-card>
                <v-card-title class="primary--text">
                  Dicas Relacionadas
                  <v-spacer/>
                  <v-btn
                    fab
                    top
                    right
                    color="primary"
                    dark
                    @click="newSubTip()"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </v-card-title>

                <v-data-table
                  id="timeline-listar"
                  loading-text="Carregando publicações..."
                  disable-filtering
                  disable-pagination
                  disable-sort
                  hide-default-footer
                  :headers="headers"
                  :items="items"
                  :loading="buzy"
                  item-key="id"
                  @click:row="(item) => handleItemSelection(item)"
                >
                  <template v-slot:header></template>
                  <template v-slot:no-data>
                    <v-alert :value="true" type="info" v-show="!buzy" outlined>Nenhum item foi encontrado</v-alert>
                  </template>

                  <template v-slot:item.actions="{ }">
                    <v-btn icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </ProgressiveLoader>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
/* eslint-disable */
import {mapMutations, mapGetters, mapState, mapActions} from "vuex";
import moment from "moment-timezone";
import SocialNetworkService from "../../../services/socialnetwork/SocialNetworkService";
import targetPublicListar from "../target-public/target-public-listar";
import ProgressiveLoader from "../../../components/progressive-loader";
import FileUpload from "vue-upload-component";
import SecurityService from "../../../services/security/SecurityService";

export default {
  components: {FileUpload, targetPublicListar, ProgressiveLoader},
  data() {
    return {
      hashtags: [
        { name: '#atendimento'},
        { name: '#carteirinha'},
        { name: '#chatbot'},
        { name: '#conectarEmpresa' },
        { name: '#consulta'},
        { name: '#dicasProtegidas'},
        { name: '#exame'},
        { name: '#vacina'},
      ],
      menuPublishDate: false,
      menuExpirationDate: false,
      sfUser: false,
      loadingMedia: false,
      timeline: {
        category: null,
        description: "",
        feedOrder: null,
        internalDescription: "",
        feedItemType: null,
        feedItemStatus: "POST",
        applicationFilter: "MUNDO_A_DENTRO",
        id: 0,
        alwaysShow: false,
        attachments: null,
        creationDate: '',
        expirationDate: '',
        publishDate: '',
        entity: 0,
        idMedia: 0,
        mediaUrl: '',
        medias: [],
        parent: this.$route.query.parentId && {id: parseInt(this.$route.query.parentId)}
      },
      showAlert: false,
      postTypeList: [
        {text: "Post no Feed", value: "POST"},
        {text: "Banner ao Entrar no App", value: "BANNER"},
        {text: "Mensagem Direcionada", value: "MESSAGE"},
        {text: "Dicas do Nick", value: "TIP"},
      ],
      items: [],
      totalPages: 1,
      page: 0,
      buzy: false,
      disableScroll: false,
      image: null,
      video: null,
      videoType: null,
      entityList: [],
      medias: [],
      isNewTip: false,
      hasParameters: false,
      urlPublish: '/publicacoes',
      urlPublishEdit: '/publicacao'
    }
  },
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
    formatPublishDateInput() {
      return this.timeline.publishDate ? moment(this.timeline.publishDate).format('DD/MM/YYYY') : '';
    },
    formatExpirationDateInput() {
      return this.timeline.expirationDate ? moment(this.timeline.expirationDate).format('DD/MM/YYYY') : '';
    },
    isNewRecord() {
      return this.timeline.id == null || this.timeline.id == 0
    },
    headers() {
      const baseHeaders = [
        {text: "Código", value: "id", cols: "6"},
        {text: "Tipo", value: "feedItemType", cols: "6"},
        {text: "Titulo", value: "internalDescription", cols: "12"},
        {text: "Status", value: "feedItemStatus", cols: "6"},
        {text: "Publicação", value: "dataPublicacao", cols: "12"},
        {text: "Ação", value: "actions", cols: "6"},
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({text: "", value: "", sortable: false, cols: ""});
      }
      return baseHeaders;
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    ...mapActions(["resetPostsStore"]),
    handleItemSelection(item) {
      this.$router.push(`${this.urlPublishEdit}/${item.id}?parentId=${this.$route.params.id}`);
    },
    newSubTip() {
      this.$router.push({
        path: `${this.urlPublishEdit}/0`,
        query: {
          parentId: this.$route.params.id,
          category: this.timeline.category,
          isNewTip: true
        }
      }).then(() => {
        window.scrollTo(0, 0);
      })
    },

    async removeMedia(index) {
      this.timeline.medias.splice(index, 1);
    },

    async addMedia() {
      if (this.timeline.medias && this.timeline.medias.length > 4) {
        this.showmsg({
          text: "Só é permitido 5 medias por publicação.",
          type: "error",
        });
      } else {
        this.timeline.medias.push({id: 0, mediaOrder: 3, type: '', attachments: []})
      }

    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () => resolve({
          name: file.name,
          body: fileReader.result,
          size: file.size,
        });

        fileReader.readAsDataURL(file);
      });
    },
    async setAttachments(media) {
      if (media.file) {

        this.readFileContent(media.file)
            .then(file => {
              let data = file.body.split(";")[0];
              let type = data.split(":")[1];

              let f = {
                type: type,
                data: file.body.split(",")[1],
              };
              media.type = type;
              media.attachments = f;
              if (f.type.includes("image")) {
                media.image = file.body;
              } else {
                media.video = file.body;
                media.videoType = f.type;
              }
            })
      }
    },
    salvarRascunho() {
      this.timeline.feedItemStatus = 'DRAFT'
      this.validatePost()
    },
    salvarPublicar() {
      this.timeline.feedItemStatus = 'PUBLISHED'
      this.validatePost()
    },
    salvarArquivar() {
      this.timeline.feedItemStatus = 'ARCHIVED'
      this.validatePost()
    },
    validatePost() {
      if (!this.timeline.feedItemType) {
        this.showmsg({
          text: "É necessário informar o Tipo de Publicação.",
          type: "error",
        });
        return false
      }

      if ((this.timeline.entity == null || this.timeline.entity <= 0)
          && this.timeline.feedItemType !== 'BANNER'
          && this.timeline.feedItemType !== 'TIP') {
        this.showmsg({
          text: "É necessário informar a Entidade.",
          type: "error",
        });
        return false
      }

      if (this.timeline.feedItemType == 'TIP' && (!this.timeline.category || this.timeline.category.trim().length <= 0)) {
        this.showmsg({
          text: "É necessário informar a Categoria da Publicação.",
          type: "error",
        });
        return false
      }

      if (this.timeline.feedItemType == 'TIP' && (!this.timeline.feedOrder || this.timeline.feedOrder.length <= 0)) {
        this.showmsg({
          text: "É necessário informar a Ordem da Publicação.",
          type: "error",
        });
        return false
      }

      if(this.timeline.feedItemStatus == 'PUBLISHED' && (!this.timeline.publishDate || this.timeline.publishDate.trim().length <= 0)){
        this.showmsg({
          text: "É necessário informar a Data de Publicação.",
          type: "error",
        });
        return false
      }

      if ((this.timeline.description == null ||
          this.timeline.description.trim().length <= 0) && (this.timeline.feedItemType == 'BANNER'
          || this.timeline.feedItemType == 'TIP')) {
        this.showmsg({
          text: "É necessário informar a Descrição Publicada.",
          type: "error",
        });
        return false
      }

      if (this.timeline.internalDescription == null || this.timeline.internalDescription.length <= 0) {
        this.showmsg({
          text: "É necessário informar o Título.",
          type: "error",
        });
        return false
      }

      if(this.timeline.medias && this.timeline.medias.length == 0 &&
          (this.timeline.feedItemType == 'POST' || this.timeline.feedItemType == 'TIP')){
        this.showmsg({
          text: "É necessário informar o conteúdo (imagem/vídeo) da publicação.",
          type: "error",
        });
        return false
      }

      if (this.timeline.medias && this.timeline.medias.length > 0) {
        for (var mediaIndex in this.timeline.medias) {
          let media = this.timeline.medias[mediaIndex]
          if (!media.mediaOrder) {
            this.showmsg({
              text: "É necessário informar a ordem de apresentação do conteúdo.",
              type: "error",
            });
            return false
          }
          if (media.attachments) {
            if (media.attachments.type && !media.attachments.type.includes("image") && !media.attachments.type.includes("video")) {
              this.showmsg({
                text: "Conteúdos aceitos: Imagem ou Vídeo.",
                type: "error",
              });
              return false
            }
            if (!media.url && !media.video && !media.image) {
              this.showmsg({
                text: "Necessário informar o arquivo.",
                type: "error",
              });
              return false;
            }
          }
        }
      }

      this.savePost();
    },
    formatDate(data) {
      if (!data) return ''
      let datehoracompleta = data.split("T");
      let datehora = datehoracompleta[0].split("-");
      if (datehora.length == 3) {
        return `${datehora[2]}/${datehora[1]}/${datehora[0]}`;
      }
    },
    feedItemTypeLabel(enumVal) {
      if (enumVal === 'POST') {
        return 'Post'
      } else if (enumVal === 'TIP') {
        return 'Dica'
      } else if (enumVal === 'MESSAGE') {
        return 'Mensagem'
      } else if (enumVal === 'BANNER') {
        return 'Banner'
      }
      return ''
    },
    feedItemStatusLabel(enumVal) {
      if (enumVal === 'PUBLISHED') {
        return 'Publicado'
      } else if (enumVal === 'DRAFT') {
        return 'Rascunho'
      } else if (enumVal === 'ARCHIVED') {
        return 'Arquivado'
      }
      return ''
    },
    async getTimeline() {
      let comp = this;
      let items = this.items;
      let getUser = this.$util.getSession();
      this.disableScroll = true;
      if (this.timeline.id && this.timeline.feedItemType == 'TIP') {
        var _res = await this._socialNetworkService
            .findFeedItemUserAdmin(getUser.id, this.timeline.id, comp.page);

        if (_res.data && _res.data.content.length > 0) {
          this.disableScroll = false;
          _res.data.content.forEach(function (elem) {
            let store = {
              id: elem.id,
              entity: elem.entity_name,
              feedOrder: elem.feed_order,
              entityId: elem.entity_id,
              contents: elem.contents,
              alwaysShow: elem.always_show,
              internalDescription: elem.internal_description,
              applicationFilter: elem.application_filter,
              dataCriacao: comp.formatDate(elem.creation_date),
              dataExpiracao: comp.formatDate(elem.expiration_date),
              dataPublicacao: comp.formatDate(elem.publish_date),
              feedItemType: comp.feedItemTypeLabel(elem.feed_item_type),
              feedItemStatus: comp.feedItemStatusLabel(elem.feed_item_status),
              contentsSize:
                  elem.contents && elem.contents.length > 150
                      ? elem.contents.substring(0, 149)
                      : elem.contents,
            };

            items.push(store);
            comp.page++;
          });
        } else {
          comp.disableScroll = true;
        }
      }
    },
    savePost() {
      this.buzy = true;

      let startHour = "00:00:00";
      let endHour = "23:59:59";

      this.timeline.publishDate = this.timeline.publishDate ? moment(this.timeline.publishDate).format('YYYY-MM-DD') : null
      this.timeline.expirationDate = this.timeline.expirationDate ? moment(this.timeline.expirationDate).format('YYYY-MM-DD') : null

      let post = {
        feed: {
          contents: this.timeline.description,
          category: this.timeline.category,
          feedOrder: this.timeline.feedOrder,
          internalDescription: this.timeline.internalDescription,
          expirationDate: this.timeline.expirationDate ? moment.utc(this.timeline.expirationDate + " " + endHour).format('YYYY-MM-DD[T]HH:mm:ss'): null,
          publishDate: this.timeline.publishDate ? moment.utc(this.timeline.publishDate + " " + startHour).format('YYYY-MM-DD[T]HH:mm:ss') : null,
          feedItemType: this.timeline.feedItemType,
          feedItemStatus: this.timeline.feedItemStatus,
          applicationFilter: this.timeline.applicationFilter,
          id: this.timeline.id,
          entityId: this.timeline.entity,
          parent: this.timeline.parent,
          alwaysShow: this.timeline.alwaysShow,
        },
        medias: this.timeline.medias,
        mediaOld: this.medias,
      };
      //Se existir, é pq foi alterado.
      if (post.medias && post.medias.length > 0) {
        post.medias.forEach((media) => {
          if (media.attachments && media.attachments.type) {
            let attachmentPayload = JSON.parse(JSON.stringify(media.attachments.data));
            media.type = media.attachments.type;
            media.attachments.data = null;
            media.video = null;
            media.id = 0;
            media.fileContent = attachmentPayload;
          }
        })
      }

      let texto =
          this.timeline.id > 0
              ? "Post alterado com sucesso"
              : "Post incluído com sucesso";

      this._socialNetworkService
          .SavePost(post)
          .then((_res) => {
            if (_res && _res.data && _res.data.id > 0) {
              this.showmsg({
                text: texto,
                type: "success",
              });
              this.$router.push(`${this.urlPublish}`);
            } else {
              this.showmsg({
                text: "Erro ao salvar a publicação!",
                type: "error",
              });
            }
          })
          .catch((ex) =>
              this.showmsg({
                text: "Erro ao salvar a publicação! " + ex.response.data.message,
                type: "error",
              })
          )
          .finally(() => {
            this.buzy = false;
          });
    },
    cancelar() {
      this.resetPostsStore();
      this.$router.push(`${this.urlPublish}`);
    },
    clearMedia(media) {
      media.image = null;
      media.attachments = [];
      media.file = null;
      media.video = null;
      media.type = null;
      media.videoType = null;
      media.mediaOrder = 3;
      media.id = 0;
    },
    init() {
      let comp = this;
      this.sfUser = localStorage.getItem('LOCALIZED_TOKEN') != null;
      if (comp.$route.query.id || comp.$route.params.id) {
        comp.buzy = true;
        comp.timeline.id = comp.$route.query.id || comp.$route.params.id;

        if (comp.timeline.id > 0) {

          this._socialNetworkService
              .getFeedId(comp.timeline.id)
              .then((_res) => {
                if (_res && _res.data && _res.data.id) {
                  comp.timeline.description = _res.data.contents;
                  comp.timeline.alwaysShow = _res.data.alwaysShow;
                  comp.timeline.internalDescription = _res.data.internalDescription;
                  comp.timeline.applicationFilter = _res.data.applicationFilter;
                  comp.timeline.entity = _res.data.entityId;
                  comp.timeline.feedOrder = _res.data.feedOrder;
                  comp.timeline.expirationDate = _res.data.expirationDate;
                  comp.timeline.publishDate = _res.data.publishDate;
                  comp.timeline.creationDate = _res.data.creationDate;
                  comp.timeline.entity = _res.data.entityId;
                  comp.timeline.category = _res.data.category;
                  comp.timeline.feedItemType = _res.data.feedItemType;
                  comp.timeline.feedItemStatus = _res.data.feedItemStatus;
                  comp.timeline.parent = _res.data.parent;

                }
              })
              .catch(() =>
                  this.showmsg({
                    text: "Erro ao carregar a publicação!",
                    type: "error",
                  })
              )
              .finally(() => {
                this.buzy = false;
                this.getMidia();
              });
        } else {
          this.buzy = false;
        }
      }
    },
    getMidia() {
      let comp = this;
      this.loadingMedia = true;
      this._socialNetworkService
          .GetMidiaFeed(this.timeline.id)
          .then((_res) => {
            if (_res.data && _res.data.length > 0) {

              _res.data.forEach((media) => {
                media.mediaOrder = media.media_order;
                let midiaStream = `data:${media.type};base64, ${media.contents}`;
                if (media.type.includes("image")) {
                  media.image = midiaStream;
                } else if (media.type.includes("video/mp4")) {
                  media.video = midiaStream;
                  media.videoType = media.type;
                }
              })

              _res.data.forEach((element) => {
                comp.timeline.medias.push(element);
                this.medias.push({
                  idFeedMedia: element.id_feed, //id_feed = feedMediaId
                  idMedia: element.id,
                });
              });
            }
          })
          .catch((ex) => {
          })
          .finally(() => {
            this.loadingMedia = false;
          });
    },
    getEntityList() {
      this.entityList = [];
      let getUser = this.$util.getSession();
      this._socialNetworkService
          .getEntityListPerUser(getUser.id)
          .then((_res) => {
            if (_res && _res.data && _res.data.length > 0) {
              _res.data.forEach((element) => {
                this.entityList.push({
                  text: element.entity_name,
                  value: element.entity_id,
                });
              });
            }
          })
          .catch(() =>
              this.showmsg({
                text: "Erro ao carregar as entidades!",
                type: "error",
              })
          )
          .finally(() => {
          });
    },
    target() {
      this.$router.push(`/target-public/${this.timeline.id}`);
    },
    loadUrlCases(){
      const {hideMenu} = this.$route.meta
      if(hideMenu){
        this.urlPublish = '/publicacoes-salesforce'
        this.urlPublishEdit = '/publicacao-salesforce'
      }
    },
    async loadUserData(){
      await this._securityService.getCurrent().then(response => {
        let session = this.$util.getSession();
        session.id = response.data.user.id;
        this.$util.setSession(session);
      })
    },
    validateAlwaysShow(){
      if(this.timeline.feedItemType !== 'BANNER'){
        this.timeline.alwaysShow = false;
      }
    },
    setPublishDate(publishDate){
      this.timeline.publishDate = publishDate;
    },
    setExpirationDate(expirationDate){
      this.timeline.expirationDate = expirationDate;
    }
  },
  beforeUpdate() {
    const newTip = this.$route.query.isNewTip;
    const category = this.$route.query.category;

    if(newTip && category && !this.hasParameters) {
      if (newTip == 'true') {
        this.isNewTip = true;
      } else {
        this.isNewTip = false;
      }

      if (category) {
        this.timeline.category = category;
      }

      this.timeline.feedItemType = 'TIP';
      this.hasParameters = true;
    }

  },
  async created() {
    this._securityService = new SecurityService();
    this._socialNetworkService = new SocialNetworkService();

    await this.loadUserData();
    await this.init();
    await this.getEntityList();
    this.loadUrlCases();
  },

};
</script>


<style>
.timeline-midia {
  width: 100%;
}
</style>

<template>
  <v-container fluid grid-list-md class="fill-height pb-15" style="background-color: #F0F0F0;">
    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <v-container
        fluid>
        <div
          v-if="showTicketForm"
          style="width: 100%;"
          class="elevation-4">
          <v-card class="pa-8 rounded-lg">
            <p
              class="font-weight-bold my-4"
              style="font-size: 18px;">
              Novo Atendimento
            </p>

            <v-form
              ref="NewTicketForm">
              <v-row>
                <v-col
                  cols="6"
                  xl="5"
                  lg="6">
                  <v-select
                    v-model="ticket.solicitation"
                    :items="solicitations"
                    item-text="name"
                    item-key="code"
                    label="Solicitação*"
                    outlined
                    return-object
                    :rules="[ formrules.required ]"
                  />
                </v-col>
                <v-col
                  cols="6"
                  xl="5"
                  lg="6">
                  <v-autocomplete
                    v-model="ticket.contract"
                    :items="contracts"
                    label="Contrato*"
                    item-text="label"
                    item-key="id"
                    outlined
                    return-object
                    :rules="[ formrules.required ]"
                  />
                </v-col>

                <v-col
                  cols="12">
                  <v-textarea
                    outlined
                    label="Descrição*"
                    v-model="ticket.description"
                    :rules="[ formrules.required ]"
                  />
                </v-col>

                <v-col
                  cols="12">
                  <v-container
                    fluid
                    class="py-4">
                    <v-row>
                      <label
                        style="font-size: 14px; color: #808080;"
                        class="mr-2">
                        Upload de arquivos
                      </label>

                      <v-menu
                        open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            height="20"
                            width="20"
                            class="rounded-circle d-flex justify-center align-center"
                            outlined
                            style="border: 2px solid #5DAAF5;">
                            <v-icon
                              color="#5DAAF5"
                              size="10">
                              fas fa-info
                            </v-icon>
                          </v-sheet>
                        </template>

                        <v-card
                          height="auto"
                          width="355"
                          color="#E0EFFE"
                          class="pa-2 rounded-lg">
                          <p
                            class="mb-0 pb-0"
                            style="color: #5DAAF5;">
                            Máximo 10 arquivos.
                          </p>

                          <p
                            style="color: #5DAAF5;">
                            Tamanho máquimo por arquivo 12MB.
                          </p>
                        </v-card>
                      </v-menu>
                    </v-row>
                  </v-container>

                  <FileDropzone
                    :height="'150px'"
                    :justify="'center'"
                    @files="ticket.files = $event"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-card-actions class="justify-end">
              <v-btn
                @click="resetTicketFlow"
                class="rounded-lg white--text mr-4"
                color="#5555A5"
                style="width: 200px; height: 44px;">
                Cancelar
              </v-btn>

              <v-btn
                @click="createCase"
                class="rounded-lg black--text"
                color="#5FD3C7"
                style="width: 200px; height: 44px;">
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <v-row
          class="pa-3" style="min-width: 100%;" v-else>
          <v-col cols="6">
            <v-card
              class="rounded-lg pa-8 fill-height elevation-4">
              <v-btn
                @click="toggleNewServiceForm"
                class="rounded-lg black--text mb-4"
                color="#5FD3C7"
                style="width: 230px; height: 44px;">
                iniciar atendimento
              </v-btn>

              <p
                class="font-weight-bold mb-4">
                Conheça o seu time de apoio
              </p>

              <div
                style="max-height: 400px; overflow-y: auto;">
                <div
                  v-for="(member, index) in supportTeam" :key="member.id">
                  <v-sheet
                    class="lighteen-2 rounded-pill"
                    :color="index % 2 === 0 ? '#E7E7FA' : '#D8EAE8'">
                    <v-container
                      fluid
                      class="pa-1">
                      <v-row
                        no-gutters>
                        <v-col
                          style="max-width: 70px;">
                          <v-avatar size="60">
                            <v-img :src="setUserImage(member.userImage)" />
                          </v-avatar>
                        </v-col>

                        <v-col
                          class="d-flex flex-column justify-center">
                          <span>
                            {{ member.userName }}
                          </span>
                          <span>
                            {{member.jobName}}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-sheet>
                  <br>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="6" v-if="!showServiceDetails">
            <v-card
              class="rounded-lg pa-8 elevation-4 fill-height">
              <p
                class="font-weight-bold">
                Histórico
              </p>

              <v-text-field
                outlined
                dense
                label="Busca..."
                class="rounded-pill"
                v-model="search">
                <template v-slot:append>
                  <v-icon
                    color="#5555A5"
                    class="serviceSearchIcon">
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>

              <v-container
                style="max-height: 400px; overflow-y: auto;">
                <v-row v-for="ticket in ticketsSearch" :key="ticket.id">
                  <v-sheet
                    style="width: 100%; border-bottom: 2px solid #E8E8E8;">
                    <v-container
                      fluid>
                      <v-row
                        no-gutters>
                        <v-col
                          cols="6">
                          <span>
                            {{ ticket.name }}
                          </span>
                        </v-col>

                        <v-col
                          cols="6"
                          class="d-flex justify-end">
                          <span
                            style="color: #808080">
                            {{ setDate(ticket.create_date) }}
                          </span>
                        </v-col>

                        <v-col
                          cols="6">
                          <span
                            style="color: #808080">
                            Protocolo {{ ticket.id }}
                          </span>

                          <v-chip
                            :color="getTicketStatus(ticket.state).color"
                            :text-color="getTicketStatus(ticket.state).textColor">
                            {{ getTicketStatus(ticket.state).label }}
                          </v-chip>
                        </v-col>

                        <v-col
                          cols="6"
                          class="d-flex justify-end">
                          <v-btn
                            color="#5555A5"
                            icon>
                            <v-icon
                              @click="toggleShowServiceDetails(ticket)"
                              size="11">
                              fas fa-chevron-right
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-sheet>
                </v-row>
              </v-container>
            </v-card>
          </v-col>

          <v-col cols="6" v-else>
            <v-card
              class="rounded-lg elevation-4 fill-height">
              <v-sheet
                color="#F6F2FC"
                width="100%"
                height="75px"
                class="d-flex align-center pl-4">
                <ActionBtn
                  :icon="'fas fa-arrow-left'"
                  @click="hideServiceDetails"
                />
              </v-sheet>

              <div
                class="pa-8">
                <v-row
                  no-gutters
                  class="d-flex flex-column">
                  <h3
                    class="font-weight-bold">
                    Detalhe do Atendimento
                  </h3>

                  <v-sheet
                    class="rounded-lg d-flex px-4 py-2 flex-column"
                    color="#EFF8F6"
                    height="70"
                    width="100%">
                    <span
                      class="mb-auto"
                      style="color: #808080">
                      Solicitação
                    </span>

                    <span>
                      Implantação
                    </span>
                  </v-sheet>

                  <h3
                    class="font-weight-bold my-4">
                    Eventos
                  </h3>

                  <div
                    style="max-height: 200px; overflow-y: auto;"
                    id="serviceHistoricBody">
                    <div
                      style="width: 100%;"
                      v-for="log in serviceHistoric"
                      :key="log.id">
                      <p
                        class="text-subtitle-2"
                        style="color: #808080">
                        {{ setCaseDate(log.create_date) }}
                      </p>

                      <v-sheet
                        class="d-flex flex-column mb-4"
                        :style="setLogClassByOrigin(log.email_from)">
                        <span v-html="log.body"></span>

                        <v-chip
                          v-for="attch in log.attachment_ids"
                          :key="attch.id"
                          @click="downloadFile(attch)"
                          color="#5DAAF5"
                          text-color="white"
                          class="text-truncate overflow-hidden my-1"
                          style="max-width: 400px;">
                          <v-icon
                            size="20"
                            color="white"
                            class="mr-2">
                            fas fa-paperclip
                          </v-icon>
                          {{ attch.name }}
                        </v-chip>
                      </v-sheet>
                    </div>
                  </div>
                </v-row>
              </div>

              <v-sheet
                color="#F6F2FC"
                width="100%"
                min-height="75"
                class="d-flex align-center justify-center flex-row align-center px-4">
                <v-container
                  fluid
                  class="fill-height">
                  <v-row
                    class="d-flex align-center">
                    <v-menu
                      offset-y
                      top>
                      <template
                        v-slot:activator="{ on, attrs }">
                        <ActionBtn
                          :on="on"
                          :attrs="attrs"
                          :icon="'fas fa-plus'"
                        />
                      </template>

                      <v-card
                        height="auto"
                        width="420"
                        color="#29252F">
                        <v-container
                          fluid>
                          <v-row
                            class="d-flex justify-center align-center">
                            <v-col
                              v-for="(action, key) in caseActions"
                              :key="key"
                              cols="4"
                              class="d-flex flex-column justify-center align-center"
                              @click="action.function">
                              <v-btn
                                outlined
                                width="100"
                                height="100"
                                color="#5FD3C7">
                                <v-icon
                                  size="50">
                                  {{ action.icon }}
                                </v-icon>
                              </v-btn>

                              <span
                                style="color: #5FD3C7">
                                {{ action.label }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-menu>

                    <v-text-field
                      outlined
                      hide-details
                      label="Digite a sua mensagem..."
                      class="rounded-pill mx-2"
                      v-model="currentCase.message"
                      @keyup.enter="createCaseEvent"
                    />

                    <ActionBtn
                      :icon="'mdi-send'"
                      :color="'#808080'"
                      @click="createCaseEvent"
                    />
                  </v-row>
                  <v-row>
                    <FileDropzone
                      :showOptions="false"
                      :filesImported="currentCase.files"
                      @files="currentCase.files = $event"
                    />
                  </v-row>
                </v-container>
              </v-sheet>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import FileDropzone from "../../components/file-dropzone/fileDropzone.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import AttachmentMixin from "./functions/attachmentMixin";
import ActionBtn from "../sendDocuments/actionBtn.vue";
import RequestMixin from "./functions/requestMixin";
import { formrules } from "./../../common/formrules";
import FormMixin from "./functions/formMixin";
import moment from 'moment';

export default {
  name: "AtendimentoImplantacao",
  components: {
    FileDropzone,
    ActionBtn,
  },
  data: () => ({
    formrules,
    cards: [],
    selectedTab: "active",
    showTicketForm: false,
    showServiceDetails: false,
    ticket: {
      solicitation: null,
      contract: null,
      description: null,
      files: [],
    },
    currentCase: {
      id: null,
      files: [],
      message: "",
    },
    search: null,
    userEmail: null,
    serviceHistoric: [],
    solicitations: [],
    supportTeam: [],
    contracts: [],
    tickets: [],
  }),

  mixins: [
    AttachmentMixin,
    RequestMixin,
    FormMixin,
  ],

  methods: {
    ...mapMutations(["loading", "showmsg"]),

    toggleNewServiceForm() {
      this.showTicketForm = !this.showTicketForm;
    },

    hideServiceDetails() {
      this.showServiceDetails = false;

      this.resetCurrentCaseForm();
    },

    async toggleShowServiceDetails(ticket) {
      this.showServiceDetails = !this.showServiceDetails;

      this.currentCase.id = ticket.id;

      await this.loadServiceComents(ticket);
    },

    formatContractText(contract){
      return (`${(contract.benefit_id ? contract.benefit_id : '-')} / ${(contract.carrier_id && contract.carrier_id.xipp_commercial_name ?
          contract.carrier_id.xipp_commercial_name : '-')} - ${contract.policy ? contract.policy : '-'} - ${contract.contract_owner_id && contract.contract_owner_id.legal_name ? contract.contract_owner_id.legal_name : '-'}`)
    },

    setCaseDate(dateString) {
      return moment(
        dateString.replace(" ", "T") + '.000Z'
      ).format("DD/MM/YYYY HH:mm");
    },

    setLogClassByOrigin(email_from) {
      if (email_from !== this.userEmail) {
        return "background-color: #EFF8F6; color: black; padding: 15px; border-radius: 25px 25px 25px 0px; !important"
      } else {
        return "background-color: #6F71B1; color: white; padding: 15px; border-radius: 25px 25px 0px 25px; !important"
      }
    },

    setUserImage(userImage) {
      return `data:image/png;base64,${userImage}`;
    },

    setDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },

    getTicketStatus(state) {
      switch (state) {
        case 'draft':
          return {
            label: "Novo",
            color: "#CDF9D9",
            textColor: "#154B24"
          };

        case 'in_progress':
          return {
            label: "Em Andamento",
            color: "#CBE2F5",
            textColor: "#14497C"
          };

        case 'finished':
          return {
            label: "Finalizado",
            color: "#e3bb19",
            textColor: "#47453b"
          };
      }
    },
  },

  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),

    ticketsSearch() {
      if (!this.search) return this.tickets;

      const lowerCaseSearch = this.search.toLowerCase();

      return this.tickets.filter((ticket) => {
        return (
          (ticket.name && ticket.name.toLowerCase().includes(lowerCaseSearch)) ||
          (ticket.id && String(ticket.id).includes(lowerCaseSearch))
        );
      });
    },

    caseActions() {
      return [
        {
          label: "Imagem",
          icon: "far fa-file-image",
          function: () => this.openFileSelector('.jpg, .jpeg, .png')
        },
        {
          label: "Arquivo",
          icon: "far fa-file",
          function: () => this.openFileSelector('.pdf, .csv, .xls, .xlsx, .txt')
        },
        // {
        //   label: "Câmera",
        //   icon: "fas fa-camera",
        //   function: () => this.stopCamera()
        // },
      ]
    },
  },

  async mounted() {
    this.loading(true);

    this.userEmail = this.$util.getSession()
      && this.$util.getSession().email
        ? this.$util.getSession().email
        : null;

    try {
      const [
        tickets,
        solicitations,
        supportTeam,
        contracts,
      ] = await Promise.all([
        this.getImplantationCase(),
        this.getTicketTypes(),
        this.getSupportTeam(),
        this.getContracts(),
      ]);

      this.solicitations = solicitations;
      this.supportTeam = supportTeam;
      this.contracts = contracts;
      this.tickets = tickets;

      this.contracts.forEach((contract) => {
        contract.label = this.formatContractText(contract);
      })

      if (this.contracts && this.contracts.length === 1) {
        this.ticket.contract === this.contract[0];
      }
    }
    catch(err) {
      this.showmsg(err)
    }

    this.loading(false);
  },
};
</script>
<style scoped>
.serviceSearchIcon {
  font-size: 20px !important;
}

</style>

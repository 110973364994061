<template>
  <v-carousel
    height="100%"
    :show-arrows="current !== 0"
    :hide-delimiters="true"
    v-model="current">
    <template #next>
      <v-btn
        icon
        @click="
          current === tour.length
          ? finishTour()
          : goToNext()">
        <v-icon
          size="36">
          mdi mdi-chevron-right
        </v-icon>
      </v-btn>
    </template>

    <v-carousel-item>
      <div
        class="fill-height"
        style="background-color: #5555A5">
        <v-container
          class="fill-height">
          <v-row
            class="fill-height">
            <v-col
              cols="12"
              class="fill-height d-flex flex-column justify-space-around align-center flex-nowrap">
              <div
                class="d-flex flex-row justify-center align-center ">
                <img
                  src="/img/icons/logos/xipp-rh-protegido-logo-redondo.png"
                  height="52px"
                  contain
                />

                <span
                  class="ml-2 text-h4 font-weight-black white--text">
                  RhProtegido
                </span>
              </div>

              <div
                class="d-flex flex-column align-center justify-center">
                <p
                  class="mb-1 text-h5 font-weight-light white--text text-center">
                  Bem-vindo ao app!
                </p>

                <p
                  class="mb-1 text-h5 font-weight-light white--text text-center">
                  É com grande satisfação que damos as boas vindas à {{ financialGroup.name }}.
                </p>

                <p
                  class="mb-1 text-h5 font-weight-light white--text text-center">
                  Estamos prontos para iniciar esta jornada com vocês.
                </p>
              </div>

              <v-img src="/img/assets/ship.svg" max-height="250px" width="100%" contain />

              <p
                class="text-h6 font-weight-bold white--text">
                Vamor começar?
              </p>

              <v-btn
                class="black--text rounded-lg"
                color="#5FD3C7"
                width="400"
                height="44"
                @click="goToNext">
                <span
                  class="font-weight-bold">
                  Iniciar tour virtual
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-carousel-item>

    <v-carousel-item
      v-for="(item, index) in tour"
      :key="index+1"
      style="background-color: #6E6E8E;">
      <v-img
        :src="item.datas"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import ImplantationService from "@/services/odoo/ImplantationService";
import { mapMutations } from "vuex";
export default {
  name: "Tour",

  data: () => ({
    current: 0,
    tour: [],
    implantationService: null,
  }),

  methods: {
    ...mapMutations(["loading", "setDoneTour"]),

    goToNext() {
      this.current++;
    },

    async findTour() {
      this.loading(true);

      await this.implantationService.FindTour()
        .then((response) => response.data)
        .then((result) => {
          if (result.length > 0) {
            this.tour = result.map((page) => (
              {
                ...page,
                datas: `data:image;base64,${page.datas}`
              }
            ))
          }
        })
        .finally(() => this.loading(false));
    },

    async finishTour() {
      this.loading(true);

      await this.implantationService.DoneTour()
        .then((response) => {
          this.setDoneTour(true);

          setTimeout(() => {
            this.$router.push("/onboarding")
          }, 500);
        })
        .finally(() => this.loading(false));
    },
  },

  computed: {
    imgShipPath() {
      return "/img/assets/ship.svg";
    },
  },

  created() {
    this.implantationService = new ImplantationService();

    const session = this.$util.getSession();
    this.financialGroup = session.financialGroup;
  },

  async mounted() {
    await this.findTour();
  },
}
</script>
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return (false)?_c(VContainer,{staticClass:"fill-height",staticStyle:{"background-color":"#F0F0F0","width":"100%","padding-bottom":"70px"},attrs:{"fluid":"","grid-list-md":""}},[_c(VCard,{staticClass:"fill-height py-4 px-8 rounded-lg",attrs:{"width":"100%"}},[_c(VTabs,{attrs:{"sliderColor":"#5FD3C7","slider-size":"3","background-color":"transparent","grow":"","centered":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_vm._l((_vm.tabs),function(tab){return _c(VTab,_vm._b({key:`${tab.id}-key`,attrs:{"color":{
          '#808080': _vm.selectedTab !== tab.id,
          '#5555A5': _vm.selectedTab === tab.id,
        },"href":`#${tab.id}`}},'v-tab',_vm.tabAttrs,false),[_vm._v(" "+_vm._s(tab.label)+" ")])}),_c(VTabsItems,{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(item,index){return _c(VTabItem,{key:index,staticClass:"pt-4",attrs:{"value":item.id}},[_c('UserMessage',{attrs:{"message":item.message}}),_c(item.component,{tag:"component"})],1)}),1)],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
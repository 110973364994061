/* eslint-disable */
import HttpService from './HttpService';
export default class ContractService {
  constructor() {
    this._httpService = new HttpService();
  }

  async findAllBeneficiariesAndDependentsByFilters(params = ""){
    return await this._httpService.get(`/api/contract/beneficiary_contracted_plan/find_all_by_filters${params}`);
  }

  async findBeneficiaryContractedPlanAndDependentsContractedPlansById(id){
    return await this._httpService.get('/api/contract/beneficiary_contracted_plan/find_beneficiary_contracted_plan_and_dependents_contracted_plans_by_id', {id});
  }

  async findBeneficiaryAndDependentsByDependentContractedPlan(id){
    return await this._httpService.get('/api/contract/beneficiary_contracted_plan/find_all_by_dependent_contract_plan_id', {dependentContractPlanId: id});
  }

  async FindByStatus(_status = 'active') {
    return await this._httpService.get('/api/odoo/contract/v2/permitted', { status: _status});
  }

  async FindAllContractsByFinancialGroupId(financialGroupdId) {
    return await this._httpService.get('/api/odoo/contract/findAllByFinancialGroupId/' + financialGroupdId);
  }

  async FindContract(financialGroupId) {
    return await this._httpService.get(`/api/odoo/contractual-agreement/${financialGroupId}`);
  }

  async AddContractualAgreement(cnpj, holderId) {
    return await this._httpService.post(`/api/odoo/contractual-agreement/cnpj/${holderId}`, { documentNumber: cnpj});
  }

  async FindContractualAgreement(financialGroupId) {
    return await this._httpService.get(`/api/odoo/contractual-agreement/onboarding/${financialGroupId}`);
  }

  async FindTechnicalProposal(financialGroupId) {
    return await this._httpService.getBlob(`/api/odoo/contractual-agreement/technical-proposal-download/${financialGroupId}`);
  }

  async FindImplantationContracts() {
    return await this._httpService.get(`/api/odoo/contractual-agreement/contracts`);
  }

  async FindAllByFilters(params) {
    return await this._httpService.get(`/api/odoo/contract/v2/permitted?${params}`);
  }

  async FindAllByFiltersV2(params) {
    return await this._httpService.get(`/api/odoo/contract/v2?${params}`);
  }

  async FindAll(params) {
    return await this._httpService.get('/api/odoo/contract/all', params);
  }

  async FindAllContractV2(params) {
    return await this._httpService.get('/api/odoo/contract/v2', params);
  }

  async FindAllActiveSubContractsByParentId(parentId) {
    return await this._httpService.get('/api/odoo/contract/findAllActiveSubContractsByParentId/' + parentId);
  }

  async FindAllFinancialGroup() {
    return await this._httpService.get('/api/odoo/financial_group');
  }

  async FindAllFinancialGroupV2(params) {
    return await this._httpService.get('/api/odoo/financial_group/v2', params);
  }

  async FindAllFinancialGroupById(id) {
    return await this._httpService.get('/api/odoo/financial_group/' + id);
  }

  async FindById(id) {
    return await this._httpService.get('/api/odoo/contract/' + id);
  }

  async FindSubContracts(id, _status = 'active') {
    return await this._httpService.get('/api/odoo/contract/' + id + '/subcontracts', { status: _status});
  }

  async FindSubContractsData(id, data) {
    return await this._httpService.get('/api/odoo/contract/' + id + '/subcontracts', data);
  }

  async findContractsByFilters(params = "") {
    return await this._httpService.get('/api/odoo/contract/v2', params);
  }

  async findSubContractsByFilters(parentId, params = "") {
    return await this._httpService.get('/api/odoo/contract/v2/' + parentId + '/subcontracts', params);
  }

  async FindIncludeSubContracts(login, include = true) {
    return await this._httpService.get('/api/odoo/contract/v2/permitted',
      {
        includeSubContracts: include,
        login
      }
    );
  }

  async FindFinancialGroupContracts(id) {
    return await this._httpService.get('/api/odoo/financial_group/' + id + '/contracts');
  }

  async FindFinancialGroupPermiteds() {
    return await this._httpService.get('/api/odoo/financial_group/permitted-odoo');
  }

  async findClientsByFinancialGroupId(id) {
    return await this._httpService.get('/api/odoo/account/?financialGroup.id='+id);
  }

  async GetSupportTeam() {
    return await this._httpService.get('/api/odoo/support-team');
  }
}

<template>
  <div>
    <SendDocumentsTable
      :model="model"
      :items="enterpriseChecklist"
      :config="config">
      <template v-slot:[`item.documentNumber`]="{ item }">
        <span v-html="item.documentNumber" />
      </template>
      <template v-slot:[`item.documentDTOS`]="{ item }">
        {{ item.documentDTOS.length }} anexos
      </template>
      <template v-slot:[`item.fill`]="{ item }">
        <div
          style="width: 100%;"
          :id="item.id">
          <ProgressLoader
            :loaded="setLoader(item.documentDTOS)"
          />
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <StatusBadge
          :label="setTemplateStatus(item).label"
          :color="setTemplateStatus(item).color"
        />
        <div
          :id="item.id"></div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <ActionBtn
          @click="openModal(item)"
        />
      </template>
    </SendDocumentsTable>

    <v-container
      fluid>
      <v-row
        class="d-flex justify-end">
        <v-btn
        height="44"
        width="200"
        outlined
        class="rounded-lg text-capitalize"
        color="#5555A5"
        @click="openDocumentModal">
        Inserir CNPJ
      </v-btn>
      </v-row>
    </v-container>

    <Modal
      ref="AddDocumentModal"
      :title="'Inserir CNPJ'"
      :dialogWidth="'590px'">
      <template
        slot="body">
        <v-container
          class="py-10 px-15">
          <v-row
            justify="center">
            <v-col
              cols="12">
              <label
                style="font-size: 14px; color: #808080;">
                Acordos contratuais*
              </label>

              <v-autocomplete
                outlined
                v-model="contractId"
                item-value="id"
                item-text="name"
                :items="contracts"
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              class="pt-0">
              <label
                style="font-size: 14px; color: #808080;">
                Insira o CNPJ*
              </label>

              <v-text-field
                outlined
                v-model="cnpj"
                placeholder="00.000.000/0000-00"
                :rules="[required]"
                v-mask="'##.###.###/####-##'"
              />
            </v-col>
            <v-btn
              large
              color="#5FD3C7"
              class="black--text rounded-lg"
              width="200"
              @click="addNewCnpj"
              :disabled="!contractId || !cnpj || cnpj.length != 18"
              :loading="addNewCnpjLoading">
              Salvar
            </v-btn>
          </v-row>
        </v-container>
      </template>
    </Modal>

    <Modal
      ref="EnterpriseModal"
      :title="title"
      @close="closeModal">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: 500px; overflow: auto;"
          class="pb-0">
          <ModalContentTable
            :items="documents"
            :config="modalConfig">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <v-sheet
                  class="rounded-circle d-flex justify-center align-center mr-1"
                  min-height="16"
                  min-width="16"
                  style="border: 2px solid #E0E0E0"
                  :color="setStatusColor({code: item.status})">
                </v-sheet>


                <span v-html="item.name" />
              </div>
            </template>

            <template v-slot:[`item.attachment`]="{ item }">
              <v-menu
                bottom
                right
                open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#5FD3C7"
                    size="17"
                    v-on="on"
                    v-bind="attrs">
                    fas fa-paperclip
                  </v-icon>
                </template>

                <v-list
                  style="background-color: #DCF0EE;">
                  <div>
                    <v-list-item
                      v-for="(document, i) in item.documents"
                      :key="i"
                      class="py-0">
                      <v-list-item-icon>
                        <v-icon
                          color="#5FD3C7"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        {{ document.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.comments`]="{ item }">
              <v-icon
                :key="item.id"
                color="#8D8D8D"
                size="17"
                @click="openCommentModal(item)">
                far fa-comment
              </v-icon>
            </template>

            <template v-slot:[`item.critics`]="{ item }">
              <v-icon
                :key="item.id"
                v-show="item.document && item.document.statusCode === 'risk'"
                color="#5555A5"
                size="17"
                @click="openCriticsModal(item)">
                fas fa-exclamation-triangle
              </v-icon>
            </template>

             <template v-slot:[`item.action`]="{ item }">
              <FileDropzone
                v-if="!item.documentTypeField || item.documentTypeField === 'file'"
                :isMultiple="false"
                @files="setFile(item, $event)"
              />

              <TextField
                v-if="['smartphone', 'email', 'text'].includes(item.documentTypeField)"
                :attrs="{
                  title: setTitle(item),
                  rules: setRuleTypes(item),
                  vMask: setDocumentTypeFieldMask(item),
                }"
                @updateValue="setDocumentValue(item, $event)"
              />
            </template>
          </ModalContentTable>

          <div
            class="pt-2 pb-7 px-3">
            <UserMessage
              :message="'Para uma melhor organização, recomendamos\
                fazer o upload de cada documento  conforme a ordem do\
                check-list.<br>Caso prefira, você também pode enviar\
                todos os arquivos de uma vez, <strong>clicando abaixo</strong>.'"
            />
          </div>

          <v-divider />

          <div
            class="px-3 pt-2">
            <v-container
              fluid>
              <v-row>
                <v-btn
                  large
                  outlined
                  color="primary"
                  class="white--text rounded-lg elevation-1 text-capitalize"
                  @click="openNewDocumentModal">
                  Inserir novo documento
                </v-btn>

                <v-spacer />

                <v-btn
                  large
                  color="primary"
                  class="white--text mr-6 rounded-lg text-capitalize"
                  width="200">
                  Cancelar
                </v-btn>

                <v-btn
                  large
                  color="#5FD3C7"
                  class="black--text rounded-lg text-capitalize"
                  width="200"
                  :disabled="checklistPayload.length === 0"
                  @click="updateChecklist">
                  Salvar
                </v-btn>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </Modal>

    <NewDocumentModal
      ref="NewDocumentModal"
      @updateNewDocument="updateChecklist"
    />

    <CommentModal
      ref="CommentModal"
      :title="commentTitle"
      :isCombo="false"
    />

    <CriticsModal
      ref="CriticsModal"
    />

    <v-snackbar
      v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import ProgressLoader from '../../components/structure/progressLoader.vue';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import StatusBadge from '../../components/structure/statusBadge.vue';
import UserMessage from '../../components/structure/userMessage.vue';
import ContractService from '../../services/odoo/ContractService';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
import DocumentService from '../../services/odoo/documentService';
import TextField from '../../components/structure/textField.vue';
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import ModalContentTable from './modalContentTable.vue';
import NewDocumentModal from './newDocumentModal.vue';
import { mapGetters, mapMutations } from 'vuex';
import { formrules } from '../../common/formrules';
import CriticsModal from './criticsModal.vue';
import CommentModal from './commentModal.vue';
import ActionBtn from './actionBtn.vue';

export default {
  name: "EnterpriseTable",

  components: {
    SendDocumentsTable,
    ModalContentTable,
    NewDocumentModal,
    ProgressLoader,
    FileDropzone,
    CriticsModal,
    CommentModal,
    UserMessage,
    StatusBadge,
    TextField,
    ActionBtn,
    Modal,
  },

  data: () => ({
    model: [],
    title: '',
    formrules,
    documents: [],
    contracts: [],
    documentService: null,
    commentTitle: "",
    cnpj: null,
    contractId: null,
    required: value => !!value || "Preenchimento obrigatório.",
    contractService: null,
    financialGroupId: null,
    addNewCnpjLoading: false,
    checklistPayload: [],
    snackbar: false,
    snackbarText: null,
    limitFileSize: 12,
  }),

  mixins: [
    DocumentsMixins,
    AttachmentMixin,
  ],

  methods: {
    ...mapMutations(["loading"]),

    async openModal(item) {
      this.title = `Documentos - ${item.documentNumber}`;

      this.documents = item.documentDTOS;

      this.$refs.EnterpriseModal.open();
    },

    closeModal() {
      this.title = '';
    },

    openNewDocumentModal() {
      this.$refs.NewDocumentModal.open(this.documents);
    },

    async openCommentModal(item) {
      this.commentTitle = "Comentários";

      this.$refs.CommentModal.open(item);
    },

    openCriticsModal(item) {
      this.$refs.CriticsModal.open([item.document]);
    },

    async updateChecklist() {
      for (let checklist of this.checklistPayload) {
        if (!!checklist.fileContent) {
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      this.loading(true);

      await this.documentService.UpdateChecklist(this.checklistPayload)
        .finally(() => this.loading(false));
    },

    setLoader(documents) {
      const documentsCompleted = documents.filter((document) => document.status === "completed");
      const amount = documentsCompleted && documentsCompleted.length > 0 ? documentsCompleted.length : 0;

      return (amount / documents.length) * 100;
    },

    async openDocumentModal() {
      this.loading(true);

      this.addNewCnpjLoading = false;

      await this.contractService.FindContract(398)
        .then((response) => response.data)
        .then((result) => this.contracts = result);

      this.$refs.AddDocumentModal.open();

      this.loading(false);
    },

    async addNewCnpj() {
      this.addNewCnpjLoading = true;

      await this.contractService.AddContractualAgreement(this.cnpj, this.contractId)
        .then((response) => response.data)
        .then((result) => {
          this.$emit('updateChecklist')
          this.cleanFields();
        })
        .finally(() => {
          this.addNewCnpjLoading = false;
        });

        this.$refs.AddDocumentModal.close();
    },

    cleanFields() {
      this.cnpj = null;
      this.contractId = null;
    },

    async getDocuments(item) {
      await this.documentService.DownloadDocument(item.id)
        .then((response) => {
          const contentDispositionHeader = response.headers['content-disposition'];
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const fileName = contentDispositionHeader
            ? fileNameRegex.exec(contentDispositionHeader)
            : 'documento.pdf';

          return {
            fileName: fileName,
            file: response.data,
          }
        })
        .then(({fileName, file}) => {
          const blob = new Blob([file], { type: "application/pdf" })
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = url;
          link.download = fileName;
          link.click();
        })
        .catch((err) => this.showmsg({ text: "Não foi possivel baixar o documento!", type: "error" }));
    },
    setTitle(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    setTextFieldMask(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    setFile(item, file) {
      item.file = file;

      this.updatePayload(item);
    },

    setDocumentValue(item, documentValue) {
      item.documentValue = documentValue;

      this.updatePayload(item);
    },

    updatePayload(documentDTO) {
      const dtoIndex = this.checklistPayload.findIndex((dto) => dto.id === documentDTO.id);

      const isFile = !documentDTO.documentTypeField || documentDTO.documentTypeField === 'file';

      if (dtoIndex === -1) {
        this.checklistPayload.push({
          id: documentDTO.id,
          fileContent: documentDTO.file,
          fileName: isFile ? documentDTO.file.name : null,
          documentValue: !isFile ? documentDTO.documentValue : null,
        })
      } else {
        if (!documentDTO.documentValue && !documentDTO.file) {
          this.checklistPayload.splice(dtoIndex, 1);
          return;
        }

        if (isFile) {
          this.checklistPayload[dtoIndex].fileContent = documentDTO.file;
          this.checklistPayload[dtoIndex].documentValue = null;
        } else {
          this.checklistPayload[dtoIndex].fileContent = null;
          this.checklistPayload[dtoIndex].documentValue = documentDTO.documentValue;
        }
      }
    },

    fileSizeValidation(files) {
      files.forEach((file) => {
        if (file.size > ( this.limitFileSize * 1000000 )) {
          this.snackbarText = `Arquivo ${file.name} esta ultrapassando tamanho máximo por arquivo de ${this.limitFileSize}MB`;
          this.snackbar = true;
          this.loading(false);
          return false;
        }
      });

      return true;
    },

    setRuleTypes(item) {
      if (!item.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'email':
          return  [this.formrules.email(item.documentValue)];

        case 'smartphone':
          return  [this.formrules.cellphone(item.documentValue)];

        default:
          return [true];
      }
    },

    setDocumentTypeFieldMask(item) {
      if (!item.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'smartphone':
          return  "(##) #####-####";
        default:
          return null
      }
    },
  },

  computed: {
    ...mapGetters(["getChecklist"]),

    enterpriseChecklist() {
      if (!this.getChecklist || this.getChecklist && this.getChecklist.length === 0) return [];

      const customer = this.getChecklist.find(
        (checklist) => checklist.checklistLevelCode === 'CUSTOMER');

      return customer
        && customer.levelDTOS
        && customer.levelDTOS.length > 0
          ? customer.levelDTOS
          : [];
    },
    config() {
      return {
        headers: [
          {
            text: 'CNPJ',
            value: 'documentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de anexos',
            value: 'documentDTOS',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: '% de Preenchimento',
            value: 'fill',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    modalConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 4,
              xl: 4,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 1,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }
          },
        ]
      }
    },
  },

  created() {
    this.documentService = new DocumentService();
    this.contractService = new ContractService();
  },

  mounted() {
    this.financialGroupId = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup.id
        : null;
  },
}
</script>
<template>
  <v-container
    fluid
    grid-list-md
    class="fill-height"
    v-if="false"
    style="background-color: #F0F0F0; width: 100%; padding-bottom: 70px;">
    <v-card
      class="fill-height py-4 px-8 rounded-lg"
      width="100%">
      <v-tabs
        v-model="selectedTab"
        sliderColor="#5FD3C7"
        slider-size="3"
        background-color="transparent"
        grow
        centered>
        <v-tab
          v-for="tab in tabs"
          :key="`${tab.id}-key`"
          v-bind="tabAttrs"
          :color="{
            '#808080': selectedTab !== tab.id,
            '#5555A5': selectedTab === tab.id,
          }"
          :href="`#${tab.id}`">
          {{ tab.label }}
        </v-tab>

        <v-tabs-items
          v-model="selectedTab">
          <v-tab-item
            v-for="(item, index) in tabs"
            :key="index"
            :value="item.id"
            class="pt-4">
            <UserMessage
              :message="item.message"
            />

            <component
              :is="item.component"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import ProgressLoader from '../../components/structure/progressLoader.vue';
import StatusBadge from '../../components/structure/statusBadge.vue';
import UserMessage from '../../components/structure/userMessage.vue';
import ContractsAndTermsTable from './contractsAndTermsTable.vue';
import DocumentService from '../../services/odoo/documentService';
import BeneficiariesTable from './beneficiariesTable.vue';
import EnterpriseTable from './enterpriseTable.vue';
import { mapMutations } from 'vuex/dist/vuex.common.js';

export default {
  name: "SendDocuments",

  components: {
    ContractsAndTermsTable,
    BeneficiariesTable,
    EnterpriseTable,
    ProgressLoader,
    UserMessage,
    StatusBadge,
  },

  data: () => ({
    selectedTab: 'enterprise',
    enterpriseMessage: "Olá! Pedimos que carregue para cada CNPJ os documentos listados abaixo:<br>\
      - Contrato Social<br>\
      - Cartão CNPJ<br>\
      - E-Social<br>\
      - 3 últimas Notas Fiscais<br>\
      - Procuração, Documento de identificação, E-mail e Celular do representante legal da empresa<br>\
      - Logo",
    contractsAndTermsMessage: "Por favor, baixe os documentos disponíveis, revise e assine.\
      Você pode fazer o upload dos documentos assinados clicando em Ação.<br>Caso a opção de assinatura \
      digital esteja disponível, você poderá realizá-la diretamente pela plataforma.",
    beneficiariesMessage: "Para seguir com o processo, precisamos que você carregue a base de beneficiários.\
      É rápido e essencial para concluirmos essa etapa.<br>Clique no ícones de download para baixar o Layout \
      de cada Benefício.",
    tabAttrs: {
      style: 'font-weight: bold; font-size: 16px; height: 100%',
    },
    documentService: null,
    financialGroupId: null,
  }),

  methods: {
    ...mapMutations(["loading", "setChecklist"]),

    async getChecklistDocuments() {
      this.loading(true);

      await this.documentService.GetChecklistDocumentsByFinancialGroupId(398)
        .then((response) => response.data)
        .then((result) => this.setChecklist(result))
        .catch((err) => this.showmsg({ text: "Não foi possivel buscar o checklist!", type: "error" }))
        .finally(() => this.loading(false));
    },
  },

  computed: {
    tabs() {
      return [
        { id: 'enterprise', label: "Empresa", message: this.enterpriseMessage, component: EnterpriseTable },
        { id: 'contracts-and-terms', label: "Contratos e Termos", message: this.contractsAndTermsMessage, component: ContractsAndTermsTable },
        { id: 'beneficiaries', label: "Beneficiários", message: this.beneficiariesMessage, component: BeneficiariesTable },
      ]
    }
  },

  created() {
    this.documentService = new DocumentService();
  },

  async mounted() {
    this.financialGroupId = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup.id
        : null;

    await this.getChecklistDocuments();
  },
};
</script>

<template>
  <Modal
    ref="modal"
    :title="'Novo Documento'"
    :useBackBtn="true"
    :useCloseBtn="false">
    <template
      slot="body">
      <div
        style="min-height: 100px; max-height: 500px; overflow: auto;"
        class="pb-0">
        <v-container>
          <v-row>
            <v-col
              cols="4">
              <label
                style="font-size: 14px; color: #808080;">
                Tipo de documento *
              </label>

              <v-autocomplete
                outlined
                v-model="documentType"
                :items="documentTypes"
                item-text="name"
                item-value="id"
                return-object
                @change="resetDocumentTypeValue"
              />
            </v-col>

            <v-col
              cols="12"
              v-if="documentType">
              <v-container
                fluid
                v-if="!documentType.documentTypeField || documentType.documentTypeField === 'file'">
                <v-row>
                  <label
                    style="font-size: 14px; color: #808080;"
                    class="mr-2">
                    Upload de arquivos
                  </label>

                  <v-sheet
                    height="20"
                    width="20"
                    class="rounded-circle d-flex justify-center align-center"
                    outlined
                    style="border: 2px solid #5DAAF5;">
                    <v-icon
                      color="#5DAAF5"
                      size="10">
                      fas fa-info
                    </v-icon>
                  </v-sheet>
                </v-row>
              </v-container>

              <FileDropzone
                v-if="!documentType.documentTypeField || documentType.documentTypeField === 'file'"
                :isMultiple="isMultiple"
                :height="'100px'"
                :justify="'center'"
                @files="setFiles($event)"
              />

              <TextField
                v-if="['smartphone', 'email', 'text'].includes(documentType.documentTypeField)"
                :attrs="{
                  title: setTitle(),
                  rules: setRuleTypes(),
                }"
                style="max-width: 500px"
                @updateValue="documentType.documentValue = $event"
              />
            </v-col>

            <v-col
              cols="12">
              <v-row
                class="d-flex justify-center">
                <v-btn
                  large
                  color="primary"
                  class="white--text mr-6 rounded-lg"
                  width="200"
                  @click="$refs.modal.close()">
                  Cancelar
                </v-btn>

                <v-btn
                  large
                  v-if="documentType"
                  color="#5FD3C7"
                  class="black--text rounded-lg"
                  width="200"
                  @click="$emit('updateNewDocument', documentType)">
                  Salvar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </Modal>
</template>

<script>
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import TextField from '../../components/structure/textField.vue';
import Modal from '../../components/structure/modal.vue';
import { formrules } from '../../common/formrules';
export default {
  name: "NewDocumentModal",

  components: {
    FileDropzone,
    TextField,
    Modal,
  },

  data: () => ({
    formrules,
    documentTypes: [],
    documentType: null,
  }),

  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    open(documentTypes) {
      if (documentTypes && documentTypes.length > 0) {
        this.documentTypes = documentTypes;
      }
      this.$refs.modal.open();
    },

    resetDocumentTypeValue() {
      this.documentType.documentValue = null;
      this.documentType.fileContent = null;
    },

    setFiles(files) {
      this.documentType.files = files;

      this.updatePayload();
    },

    setTitle() {
      switch(this.documentType.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Texto/Link";
      }
    },

    setRuleTypes() {
      if (!this.documentType.documentValue) return [true];

      switch(this.documentType.documentTypeField) {
        case 'email':
          return [this.formrules.email(this.documentType.documentValue)];

        case 'smartphone':
          return [this.formrules.cellphone(this.documentType.documentValue)];

        default:
          return [true];
      }
    },

    updatePayload() {
      const isFile = !this.documentType.documentTypeField || this.documentType.documentTypeField === 'file';

      if (isFile) {
        if (this.isMultiple) {
          this.documentType.filesContent = this.documentType.files;
        } else {
          this.documentType.fileContent = this.documentType.files;
        }

        this.documentType.documentValue = null;
      } else {
        this.documentType.fileContent = null;
      }
    },
  }
}
</script>
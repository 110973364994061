import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-0 pt-0",attrs:{"fluid":""}},[_c(VDataTable,_vm._g(_vm._b({attrs:{"fixed-header":"","hide-default-header":"","page":_vm.page,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":true,"loading-text":_vm.loadingMessage,"footer-props":{
        disablePagination: _vm.loading,
      }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return [_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex align-center py-0",staticStyle:{"min-height":"60px","background-color":"#F6F2FC !important","margin":"0 1px"}},_vm._l((props.headers),function(header,index){return _c(VCol,_vm._b({key:index,class:`d-flex justify-${header.align}`},'v-col',header.attrs,false),[_c('label',{staticClass:"text-subtitle-1 black--text"},[_vm._v(" "+_vm._s(header.text)+" ")])])}),1)],1)]}},{key:"item",fn:function(props){return [_c(VContainer,{staticClass:"py-1 px-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex align-center",staticStyle:{"margin":"0 1px"}},_vm._l((props.headers),function(header,i){return _c(VCol,_vm._b({key:i,class:`d-flex justify-${header.align}`},'v-col',header.attrs,false),[_vm._t(`item.${header.value}`,null,null,props)],2)}),1),_c(VDivider)],1)]}}],null,true),model:{value:(_vm.localModel),callback:function ($$v) {_vm.localModel=$$v},expression:"localModel"}},'v-data-table',_vm.config,false),_vm.config.on))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
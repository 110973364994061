import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal",attrs:{"title":'Novo Documento',"useBackBtn":true,"useCloseBtn":false}},[_c('template',{slot:"body"},[_c('div',{staticClass:"pb-0",staticStyle:{"min-height":"100px","max-height":"500px","overflow":"auto"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('label',{staticStyle:{"font-size":"14px","color":"#808080"}},[_vm._v(" Tipo de documento * ")]),_c(VAutocomplete,{attrs:{"outlined":"","items":_vm.documentTypes,"item-text":"name","item-value":"id","return-object":""},on:{"change":_vm.resetDocumentTypeValue},model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}})],1),(_vm.documentType)?_c(VCol,{attrs:{"cols":"12"}},[(!_vm.documentType.documentTypeField || _vm.documentType.documentTypeField === 'file')?_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c('label',{staticClass:"mr-2",staticStyle:{"font-size":"14px","color":"#808080"}},[_vm._v(" Upload de arquivos ")]),_c(VSheet,{staticClass:"rounded-circle d-flex justify-center align-center",staticStyle:{"border":"2px solid #5DAAF5"},attrs:{"height":"20","width":"20","outlined":""}},[_c(VIcon,{attrs:{"color":"#5DAAF5","size":"10"}},[_vm._v(" fas fa-info ")])],1)],1)],1):_vm._e(),(!_vm.documentType.documentTypeField || _vm.documentType.documentTypeField === 'file')?_c('FileDropzone',{attrs:{"isMultiple":_vm.isMultiple,"height":'100px',"justify":'center'},on:{"files":function($event){return _vm.setFiles($event)}}}):_vm._e(),(['smartphone', 'email', 'text'].includes(_vm.documentType.documentTypeField))?_c('TextField',{staticStyle:{"max-width":"500px"},attrs:{"attrs":{
                title: _vm.setTitle(),
                rules: _vm.setRuleTypes(),
              }},on:{"updateValue":function($event){_vm.documentType.documentValue = $event}}}):_vm._e()],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"white--text mr-6 rounded-lg",attrs:{"large":"","color":"primary","width":"200"},on:{"click":function($event){return _vm.$refs.modal.close()}}},[_vm._v(" Cancelar ")]),(_vm.documentType)?_c(VBtn,{staticClass:"black--text rounded-lg",attrs:{"large":"","color":"#5FD3C7","width":"200"},on:{"click":function($event){return _vm.$emit('updateNewDocument', _vm.documentType)}}},[_vm._v(" Salvar ")]):_vm._e()],1)],1)],1)],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
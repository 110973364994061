import HttpService from './HttpService';

export default class DocumentService {
  constructor() {
    this._httpService = new HttpService();
  }

  async GetChecklistDocumentsByFinancialGroupId(financialGroupId) {
    return await this._httpService.get(`/api/odoo/contractual-agreement/checklist_documents/${financialGroupId}`);
  }

  async CreateFamilyGroup(payload) {
    return await this._httpService.post(`/api/odoo/contractual-agreement/holder`, payload);
  }

  async AddNewBeneficiary(payload) {
    return await this._httpService.post(`/api/odoo/contractual-agreement/beneficiary`, payload);
  }

  async GetChecklistDocuments() {
    return await this._httpService.get(`/api/odoo/document_type/?checklistLevelCode=recipient_doc`);
  }

  async GetCommentsByDocumentId(documentId) {
    return await this._httpService.get(`/api/odoo/mail_message/deployment_carrier_document/${documentId}`);
  }

  async AddComment(payload) {
    return await this._httpService.post(`/api/odoo/mail_message/deployment_carrier_document/internal_comment`, payload);
  }

  async UpdateChecklist(payload) {
    return await this._httpService.put(`/api/odoo/deployment_carrier_document`, payload);
  }

  async DownloadDocument(documentId) {
    return await this._httpService.get(`/api/odoo/content_version/${documentId}/download`);
  }
}

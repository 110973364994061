<template>
  <v-container
    fluid
    class="px-0 pt-0">
    <v-data-table
      v-model="localModel"
      v-bind="config"
      v-on="config.on"
      fixed-header
      hide-default-header
      :page.sync="page"
      :items="items"
      :loading="loading"
      :hide-default-footer="true"
      :loading-text="loadingMessage"
      :footer-props="{
        disablePagination: loading,
      }">
      <template v-slot:header="{ props }">
        <v-container
          fluid
          class="pa-0">
          <v-row
            style="
              min-height: 60px;
              background-color: #F6F2FC !important;
              margin: 0 1px;"
            class="d-flex align-center py-0">
            <v-col
              v-for="(header, index) in props.headers"
              :key="index"
              :class="`d-flex justify-${header.align}`"
              v-bind="header.attrs">
              <label
                class="text-subtitle-1 black--text">
                {{ header.text }}
              </label>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item="props">
        <v-container
          fluid
          class="py-1 px-0">
          <v-row
            class="d-flex align-center"
            style="margin: 0 1px;">
            <v-col
              v-for="(header, i) in props.headers"
              :key="i"
              :class="`d-flex justify-${header.align}`"
              v-bind="header.attrs">
              <slot
                :name="`item.${header.value}`"
                v-bind="props"
              />
            </v-col>
          </v-row>

          <v-divider />
        </v-container>
      </template>
    </v-data-table>
</v-container>
</template>

<script>
export default {
  name: "ModalContentTable",

  data: () => ({
    page: 1,
    localModel: [],
  }),

  props: {
    config: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Carregando..."
    },
  },

  watch: {
    localModel: {
      immediate: true,
      handler(newVal) {
        this.$emit('updateModel', [...newVal]);
      },
    },
  },
}
</script>

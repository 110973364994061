<template>
  <v-text-field
    v-bind="attrs"
    outlined
    v-model="text"
    :class="{
      'textfield-styles': !!attrs.title,
      'rounded-lg v-input--is-focused v-aplication primary--text': true,
    }"
    hide-details
    @focus="applyFocus"
    @focusout="focus = false"
    v-mask="attrs.vMask">
    <template
      v-slot:prepend-inner
      class="ma-0 pa-0">
      <div
        class="d-flex align-center justify-center v-aplication v-primary--text prepend-styles rounded-l-lg"
        style="border-right: 2px solid; background-color: #F6F2FC;">
        <span>
          {{ attrs.title }}
        </span>
      </div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "TextField",

  data: () => ({
    focus: false,
    text: null,
  }),

  props: {
    attrs: {
      type: Object,
      required: false,
    },
  },

  watch: {
    text: {
      handler(newValue) {
        this.$emit('updateValue', newValue);
      }
    }
  },

  methods: {
    applyFocus() {
      setTimeout(() => {
        this.focus = true
      }, 250);
    }
  }
}
</script>

<style>
.prepend-styles {
  height: 56px; width: 80px;
}
.textfield-styles {
  > .v-input__control {
    > .v-input__slot {
      padding: 0 12px 0 0 !important;

      > .v-input__prepend-inner {
        margin: 0;
        height: 100%;
      }
    }
  }
}
</style>
<template>
  <v-container
    fluid
    class="fill-height"
    style="padding-bottom: 80px; background-color: #F0F0F0;">
    <v-card
      class="fill-height elevation-4 white rounded-xl pa-8"
      width="100%">
      <v-row>
        <v-col
          xl="3"
          lg="3">
          <v-img
            height="123"
            contain
            style="border: 2px solid #E4E4E4;"
            :src="onboardingLogo"
          />
        </v-col>

        <v-col
          xl="6"
          lg="6"
          class="d-flex flex-column">
          <p
            class="mb-5 font-weight-medium text-subtitle-1"
            v-html="welcomeText">
          </p>
        </v-col>

        <v-col
          xl="3"
          lg="3">
          <p
            class="mb-5 font-weight-bold">
            Proposta Técnica Comercial
          </p>

          <v-btn
            block
            class="black--text rounded-lg  text-subtitle-1 font-weight-bold"
            color="#5FD3C7"
            style="height: 44px;"
            @click="downloadProposal"
            :loading="proposalBtnLoading">
            <v-icon
              left
              size="22"
              color="#1b1b1b">
              mdi mdi-script-text
            </v-icon>
            <span
              class="text-capitalize text-subtitle-1 front-weight-bold">
              Visualizar Proposta
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-card
          class="elevation-4 rounded-xl mb-4"
          width="100%"
          min-height="230"
          v-for="(proposal, index) in proposals"
          :key="`proposal-${index}`">
          <v-container
            fluid>
            <v-row
              class="fill-height px-4">
              <v-col
                xl="3"
                lg="3"
                  :class="{
                    'd-flex align-items-space-between justify-space-between flex-wrap': $vuetify.breakpoint.lgAndUp,
                    'd-flex align-start justify-space-between flex-wrap': $vuetify.breakpoint.mdAndDown,
                  }">
                <div
                  class="d-flex flex-column">
                  <div
                    class="d-flex justify-space-between">
                    <p
                      class="font-weight-bold">
                      {{ proposal.carrierName }}
                    </p>

                    <p
                      class="font-weight-bold">
                      {{ proposal
                        ? !Number.isNaN(calculePercentage(proposal))
                          && Number.isInteger(calculePercentage(proposal))
                            ? calculePercentage(proposal)
                            : calculePercentage(proposal).toFixed(2)
                        : 0
                      }}%
                    </p>
                  </div>

                  <div
                    style="height: 30px; width: 100%;">
                    <ProgressLoader
                      :loaded="calculePercentage(proposal)"
                      :hideLoader="true"
                    />
                  </div>

                  <div
                    class="d-flex justify-space-between">
                    <p>
                      Início da Vigência:
                    </p>

                    <p
                      class="font-weight-bold">
                      {{ moment(proposal.initialDate, "YYYY-MM-DD").format("DD/MM/YYYY") }}
                    </p>
                  </div>

                  <div
                    class="d-flex justify-space-between">
                    <p>
                      Finalização do Projeto:
                    </p>

                    <p
                      class="font-weight-bold">
                      {{ moment(proposal.finalDate, "YYYY-MM-DD").format("DD/MM/YYYY") }}
                    </p>
                  </div>
                </div>

                <v-btn
                  large
                  outlined
                  color="#5555A5"
                  :class="{
                    'rounded-lg mb-2': true,
                    'mt-auto': $vuetify.breakpoint.lgAndUp
                  }"
                  @click="openTimelineModal(proposal)">
                  <span
                    class="text-capitalize text-subtitle-1 front-weight-bold">
                    Detalhar Cronograma
                  </span>
                </v-btn>
              </v-col>

              <v-col
                xl="9"
                lg="9"
                cols="12">
                <Stepper
                  :steps="proposal.steps"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
    </v-card>

    <TimelineModal
      ref="TimelineModal"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import AppConfigurationService from '../../services/odoo/appConfigurationService';
import FinancialGroupService from '../../services/odoo/FinancialGroupService';
import ProgressLoader from '../../components/structure/progressLoader.vue';
import ImplantationMixins from '../../shared/mixins/implantationMixins';
import { mapMutations, mapGetters } from 'vuex/dist/vuex.common.js';
import ContractService from '../../services/odoo/ContractService';
import Stepper from '../../components/structure/stepper.vue';
import TimelineModal from './timelineModal.vue';
import moment from 'moment';

import {extension, lookup} from "mime-types";
import DocumentService from '../../services/odoo/documentService';
import {saveAs} from "file-saver";
export default {
  name: "Onboarding",

  components: {
    ProgressLoader,
    TimelineModal,
    Stepper,
  },

  data: () => ({
    _appConfigurationService: null,
    _financialGroupService: null,
    _odooContractService: null,
    _documentService: null,

    financialGroupId: null,
    financialGroupName: null,
    welcomeText: null,
    moment,
    proposalBtnLoading: false,
  }),

  mixins: [
    ImplantationMixins,
  ],

  methods: {
    ...mapMutations({
      showmsg: 'showmsg',
      loading: 'loading',
      setProposals: 'setProposals',
      setOnboardingLogo: 'setOnboardingLogo',
    }),

    async downloadProposal() {
      this.proposalBtnLoading = true;

      await this._odooContractService.FindTechnicalProposal(this.financialGroupId)
        .then((attachmentData) => {
          // var file_name = attachment.name;
          // var _correct_extension = attachment && attachment.mimetype ? extension(attachment.mimetype) : ''
          // var extension_dot = "." + _correct_extension
          // if(!file_name.endsWith(extension_dot)) {
          //   file_name = file_name + extension_dot
          // }

          console.log(attachmentData)
          saveAs(
            new Blob([attachmentData.data], {type: "application/pdf"}),
            "Proposta.pdf"
          );


          // console.log(response)
          // const contentDispositionHeader = response.headers['content-disposition'];
          // const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          // const fileName = contentDispositionHeader
          //   ? fileNameRegex.exec(contentDispositionHeader)
          //   : 'Proposta.pdf';

          // return {
          //   fileName: fileName,
          //   file: response.data,
          // }
        })
        // .then(({fileName, file}) => {
        //   const blobParts = this.$util.base64ToBytesArray(file);
        //   console.log("blobParts")
        //   console.log(blobParts)
        //   const blob = new Blob(blobParts, { type: "application/pdf" })
        //   console.log("blob")
        //   console.log(blob)
        //   const url = window.URL.createObjectURL(blob);
        //   const link = document.createElement('a');

        //   link.href = url;
        //   link.download = fileName;
        //   link.click();
        // })
        .catch((err) => this.showmsg({ text: "Não foi possivel baixar proposta!", type: "error" }))
        .finally(() => this.proposalBtnLoading = false);
    },

    async getLogo() {
      await this._financialGroupService.GetLogoByFinancialGroupId(this.financialGroupId)
        .then((response) => response.data)
        .then((result) => {
          const image = result.image;
          if (image) {
            this.setOnboardingLogo(`data:image/png;base64,${image}`);
          }
        })
        .catch((err) => this.showmsg({ text: "Não foi possivel buscar a logo!", type: "error" }));
    },

    async GetContractualAgreement() {
      await this._odooContractService.FindContractualAgreement(this.financialGroupId)
        .then((response) => response.data)
        .then((result) => this.setProposals(result))
        .catch((err) => this.showmsg({ text: "Não foi possivel buscar o Grupo Financeiro!", type: "error" }));
    },

    async getWelcomeText() {
      await this._appConfigurationService.GetWelcomeText()
        .then((response) => response.data)
        .then((result) => {
          this.welcomeText = result;

          if (this.welcomeText && this.welcomeText.includes('{financialGroupName}')) {
            this.welcomeText.replace('{financialGroupName}', this.financialGroupName)
          }
        })
        .catch((err) => this.showmsg({ text: "Não foi possivel buscar a mensagem de Boas Vindas!", type: "error" }));
    },

    openTimelineModal(proposal) {
      this.$refs.TimelineModal.open(proposal);
    },
  },

  computed: {
    ...mapGetters({
      proposals: 'getProposals',
      onboardingLogo: 'onboardingLogo',
    }),
  },

  created() {
    this._appConfigurationService = new AppConfigurationService();
    this._financialGroupService = new FinancialGroupService();
    this._odooContractService = new ContractService();
    this._documentService = new DocumentService();
  },

  async mounted() {
    this.loading(true);

    const financialGroup = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup
        : null;

    this.financialGroupId = financialGroup ? financialGroup.id : null;
    this.financialGroupName = financialGroup ? financialGroup.name : null;

    await Promise.all([
      this.getLogo(),
      this.getWelcomeText(),
      this.GetContractualAgreement(),
    ]);

    this.loading(false);
  }
}
</script>